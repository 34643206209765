import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../components/Navigation";
import { pictos } from "../assets/img/pictos";
import { Avatar } from "@mui/joy";
import _, { upperFirst } from "lodash";
import moment from "moment/moment";
import "../styles/contacts.css";
import {
  NotificationsRedux,
  accepterDemandeRedux,
  annulerDemandeRedux,
  chatSelect,
  contactsRedux,
  demandesRedux,
  invitationsRedux,
  refreshToken,
  refuserDemandeRedux,
} from "../redux/profilService";
import { useLocation, useNavigate } from "react-router-dom";
import { chatSelected } from "./fonctions";
import { UpdateNotification } from "../redux/updatesServices";
import { useContext } from "react";
import { NotifNbreContext } from "../Context";
import { CircularProgress } from "@material-ui/core";
import Breadcrumb from "../components/Breadcrumb";
import { useEffect } from "react";
import Chemin from "../components/Chemin";
import { addHistorique, addRdv } from "../redux/profilSlice";
import ErrorPopup from "../components/ErrorPopup";
import textConnect from "../textConnect.json";
import { PoliceDefaut } from "../helpers/contants";

const NotificationsPage = () => {
  const monProfil = useSelector((state) => state.profil.profil);
  const listeNotifications = useSelector((state) => state.profil.notifications);
  const accessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector((state) => state.user.refresh);

  const dispatch = useDispatch();
  const navigationPage = useNavigate();

  const nbreNotif = useContext(NotifNbreContext);

  const [openRefuserDemande, setOpenRefuserDemande] = useState(false);
  const [selectInvitation, setSelectInvitation] = useState();
  const [selectNotif, setSelectNotif] = useState();

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  const ouvrirRefuser = (id, id_notif) => {
    setSelectInvitation(id);
    setSelectNotif(id_notif);
    setOpenRefuserDemande(true);
    // console.log(id)
  };
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const closeErrorHandle = () => {
    setOpenError(false);
  };

  const [circle, setCircle] = useState(false);
  const [circle2, setCircle2] = useState(false);
  const messageHandle = (
    nom,
    prenom,
    fonction,
    idChat,
    photoChat,
    organisationContact,
    photoOrganisation,
    roomChat,
    id_notif,
    structureChat
  ) => {
    setLoading(true);
    UpdateNotification(id_notif, accessToken)
      .then((reponse) => {
        chatSelect(
          chatSelected(
            nom,
            prenom,
            fonction,
            idChat,
            photoChat,
            organisationContact,
            photoOrganisation,
            structureChat
          ),
          dispatch,
          navigationPage,
          `/chat/${idChat}/${roomChat}/1`,
          accessToken,
          setLoading
        );
        NotificationsRedux(accessToken).then((reponse) => {
          dispatch({
            type: "Profil/addNotifications",
            payload: reponse.data.filter(
              (item) =>
                item.participant_recepteur.id === monProfil.id &&
                item.etat === false
            ),
          });
          nbreNotif.setNbre(
            reponse.data.filter(
              (item) =>
                item.participant_recepteur.id === monProfil.id &&
                item.etat === false
            ).length
          );
        });
      })
      .catch((erreur) => {
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              messageHandle(
                nom,
                prenom,
                fonction,
                idChat,
                photoChat,
                organisationContact,
                photoOrganisation,
                roomChat,
                id_notif,
                structureChat
              );
            });
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };
  const rdvHandle = (id_notif, rdv) => {
    setLoading(true);
    if (rdv.data_target !== null) {
      // console.log(rdv)
      try {
        dispatch(addRdv(rdv));
        navigationPage("/rendezvous");
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      UpdateNotification(id_notif, accessToken)
        .then((reponse) => {
          // console.log(reponse)
          setLoading(false);
          // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
          NotificationsRedux(accessToken).then((reponse) => {
            dispatch({
              type: "Profil/addNotifications",
              payload: reponse.data.filter(
                (item) =>
                  item.participant_recepteur.id === monProfil.id &&
                  item.etat === false
              ),
            });

            nbreNotif.setNbre(
              reponse.data.filter(
                (item) =>
                  item.participant_recepteur.id === monProfil.id &&
                  item.etat === false
              ).length
            );
          });
        })
        .catch((erreur) => {
          setLoading(false);
          if (!netStatus) {
            setMsgError(textConnect.internet);
            setOpenError(true);
          }
          if (erreur.response) {
            if (erreur.response.status !== 401) {
              setMsgError(textConnect.error);
              setOpenError(true);
            }
            if (erreur.response.status === 401) {
              refreshToken(refresh_token).then(async (reponse) => {
                dispatch({
                  type: "user/addRefresh",
                  payload: reponse.data.refresh,
                });
                dispatch({
                  type: "user/addAccess",
                  payload: reponse.data.access,
                });
                console.log("token refresh...");

                setLoading(false);
                UpdateNotification(id_notif, accessToken);
              });
            }
          } else {
            setMsgError(textConnect.internet);
            setOpenError(true);
          }
        });
    }
  };

  const accepterHandle = (idInvitation, id_notif) => {
    setLoading(true);
    if (idInvitation === "" || idInvitation === undefined) {
      UpdateNotification(id_notif, accessToken)
        .then((reponse) => {
          // console.log(reponse)
          // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
          NotificationsRedux(accessToken).then((reponse) => {
            dispatch({
              type: "Profil/addNotifications",
              payload: reponse.data.filter(
                (item) =>
                  item.participant_recepteur.id === monProfil.id &&
                  item.etat === false
              ),
            });

            nbreNotif.setNbre(
              reponse.data.filter(
                (item) =>
                  item.participant_recepteur.id === monProfil.id &&
                  item.etat === false
              ).length
            );
            setLoading(false);
          });
        })
        .catch((erreur) => {
          setLoading(false);
          if (!netStatus) {
            setMsgError(textConnect.internet);
            setOpenError(true);
          }
          if (erreur.response) {
            if (erreur.response.status !== 401) {
              setMsgError(textConnect.error);
              setOpenError(true);
            }
          } else {
            setMsgError(textConnect.internet);
            setOpenError(true);
          }
        });
    }

    accepterDemandeRedux(accessToken, idInvitation)
      .then((reponse) => {
        // console.log('demande accepté : ', reponse.data)
        contactsRedux(accessToken).then((reponse) => {
          // console.log(reponse.data)

          dispatch({
            type: "Profil/addContacts",
            payload: reponse.data,
          });

          demandesRedux(accessToken).then((reponse) => {
            // console.log(reponse.data)

            dispatch({
              type: "Profil/addDemandes",
              payload: reponse.data,
            });
          });

          invitationsRedux(accessToken).then((reponse) => {
            // console.log(reponse.data)

            dispatch({
              type: "Profil/addInvitations",
              payload: reponse.data,
            });
          });
        });

        UpdateNotification(id_notif, accessToken)
          .then((reponse) => {
            // console.log(reponse)
            // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
            NotificationsRedux(accessToken).then((reponse) => {
              dispatch({
                type: "Profil/addNotifications",
                payload: reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ),
              });

              nbreNotif.setNbre(
                reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ).length
              );
              setLoading(false);
            });
          })
          .catch((erreur) => {
            setLoading(false);
            if (!netStatus) {
              setMsgError(textConnect.internet);
              setOpenError(true);
            }
          });
      })
      .catch((erreur) => {
        setLoading(false);
        if (erreur.request) {
          if (erreur.request.status === 400 || erreur.request.status === 403) {
            // console.log(erreur.request.status)

            UpdateNotification(id_notif, accessToken)
              .then((reponse) => {
                // console.log(reponse)
                // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
                NotificationsRedux(accessToken).then((reponse) => {
                  dispatch({
                    type: "Profil/addNotifications",
                    payload: reponse.data.filter(
                      (item) =>
                        item.participant_recepteur.id === monProfil.id &&
                        item.etat === false
                    ),
                  });

                  nbreNotif.setNbre(
                    reponse.data.filter(
                      (item) =>
                        item.participant_recepteur.id === monProfil.id &&
                        item.etat === false
                    ).length
                  );
                });
              })
              .catch((erreur) => {
                if (!netStatus) {
                  setMsgError(textConnect.internet);
                  setOpenError(true);
                }
                if (erreur.response) {
                  if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error);
                    setOpenError(true);
                  }
                }
                if (erreur.response.status === 401) {
                  refreshToken(refresh_token).then(async (reponse) => {
                    dispatch({
                      type: "user/addRefresh",
                      payload: reponse.data.refresh,
                    });
                    dispatch({
                      type: "user/addAccess",
                      payload: reponse.data.access,
                    });
                    console.log("token refresh...");

                    setLoading(false);
                    accepterHandle(idInvitation, id_notif);
                  });
                }
              });
          }
        }
      });
  };
  const refuserHandle = (idInvitation, id_notif) => {
    // setLoading(true)
    setCircle(true);
    if (idInvitation === "" || idInvitation === undefined) {
      UpdateNotification(id_notif, accessToken)
        .then((reponse) => {
          // console.log(reponse)
          // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
          // setLoading(false)
          setCircle(false);
          NotificationsRedux(accessToken).then((reponse) => {
            dispatch({
              type: "Profil/addNotifications",
              payload: reponse.data.filter(
                (item) =>
                  item.participant_recepteur.id === monProfil.id &&
                  item.etat === false
              ),
            });

            nbreNotif.setNbre(
              reponse.data.filter(
                (item) =>
                  item.participant_recepteur.id === monProfil.id &&
                  item.etat === false
              ).length
            );
          });
        })
        .catch((erreur) => {
          // setLoading(false)
          setCircle(false);
          if (!netStatus) {
            setMsgError(textConnect.internet);
            setOpenError(true);
          }
          if (erreur.response) {
            if (erreur.response.status !== 401) {
              setMsgError(textConnect.error);
              setOpenError(true);
            }
          }
        });
    }

    refuserDemandeRedux(accessToken, idInvitation)
      .then((reponse) => {
        // setLoading(false)
        // console.log('demande refusé : ', reponse.data)

        contactsRedux(accessToken).then((reponse) => {
          // console.log(reponse.data)
          dispatch({
            type: "Profil/addContacts",
            payload: reponse.data,
          });

          demandesRedux(accessToken).then((reponse) => {
            // console.log(reponse.data)

            dispatch({
              type: "Profil/addDemandes",
              payload: reponse.data,
            });
          });

          invitationsRedux(accessToken).then((reponse) => {
            // console.log(reponse.data)

            dispatch({
              type: "Profil/addInvitations",
              payload: reponse.data,
            });
          });
        });

        UpdateNotification(id_notif, accessToken)
          .then((reponse) => {
            // console.log(reponse)
            // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
            NotificationsRedux(accessToken).then((reponse) => {
              dispatch({
                type: "Profil/addNotifications",
                payload: reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ),
              });

              nbreNotif.setNbre(
                reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ).length
              );
              setCircle(false);
              setOpenRefuserDemande(false);
            });
          })
          .catch((erreur) => {
            setCircle(false);
            if (!netStatus) {
              setMsgError(textConnect.internet);
              setOpenError(true);
            }
            if (erreur.response) {
              if (erreur.response.status !== 401) {
                setMsgError(textConnect.error);
                setOpenError(true);
              }
            }
          });
      })
      .catch((erreur) => {
        // setOpenRefuserDemande(false)
        setCircle(false);
        if (erreur.request) {
          if (erreur.request.status === 400 || erreur.request.status === 403) {
            // console.log(erreur.request.status)

            UpdateNotification(id_notif, accessToken)
              .then((reponse) => {
                // console.log(reponse)
                // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
                NotificationsRedux(accessToken).then((reponse) => {
                  dispatch({
                    type: "Profil/addNotifications",
                    payload: reponse.data.filter(
                      (item) =>
                        item.participant_recepteur.id === monProfil.id &&
                        item.etat === false
                    ),
                  });

                  nbreNotif.setNbre(
                    reponse.data.filter(
                      (item) =>
                        item.participant_recepteur.id === monProfil.id &&
                        item.etat === false
                    ).length
                  );
                });
              })
              .catch((erreur) => {
                if (erreur.request) {
                  if (erreur.request.status === 400) {
                    // console.log(erreur.request.status)
                    // alert('Déjà en contact')
                  }
                }
                // console.log(erreur)
              });
          }
          // if (erreur.request.status === 0) {
          //     // console.log(erreur.request)
          //     setMsgError('Vérifiez votre connexion internet')
          //     setOpenError(true)
          // }
        }
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              refuserHandle(idInvitation, id_notif);
            });
          }
        }
      });
  };
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter((item) => item.nom === "Accueil");
  breadcrumbs_temp.push({ url: location.pathname, nom: "Notifications" });
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
    dispatch(addHistorique("/"));

    // if (listeNotifications.length !== 0) {
    //     setLoading(false)
    // }
  }, []);

  return (
    <Container fluid>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />
      {/* Modal confirmation réfuser demande */}
      <Modal
        className="d-flex align-items-center"
        show={openRefuserDemande}
        style={{ fontFamily: PoliceDefaut }}
      >
        <Modal.Header style={{ color: "white", backgroundColor: "#17418d" }}>
          <h4>Confirmation</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment refuser cette demande ?
            <div className="d-flex justify-content-end mt-3">
              {/* <Button className='btn-recup' onClick={() => refuserDemandeRedux(accessToken, dispatch, selectInvitation, navigationPage, nbreNotif.setNbre, monProfil.id, setOpenRefuserDemande)}>Confirmer</Button> */}
              {circle ? (
                <CircularProgress />
              ) : (
                <Button
                  className="btn-recup"
                  onClick={() => refuserHandle(selectInvitation, selectNotif)}
                >
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenRefuserDemande(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <Row className="">
        <Navigation
          button={false}
          titre={"Notifications"}
          icone={pictos.notification_rond_icone}
          photo={monProfil.photo_path}
          retour="dashboard"
          menus={true}
        />
      </Row>
      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <Row className="container-contacts">
          {listeNotifications.length !== 0 ? (
            listeNotifications
              .filter((item) => item.participant_recepteur.id === monProfil.id)
              .map((item, index) => (
                <Row
                  key={index}
                  className="contact-item d-flex justify-content-center"
                  style={{ width: "500px" }}
                >
                  {item.type === "INVITATION" ? (
                    <>
                      <Col
                        sm="auto"
                        style={{ width: "fit-content" }}
                        className="p-0 mx-2 photo-profil-contact d-flex align-items-center"
                      >
                        <Avatar
                          id="photoProfil"
                          className=""
                          src={item.participant_emeteur.photo}
                          style={{}}
                        />
                      </Col>

                      <Col className="p-1">
                        <Row className="">
                          <Col className="noms-participant">
                            {`${upperFirst(
                              item.participant_emeteur.prenom.toLowerCase()
                            )} ${upperFirst(
                              item.participant_emeteur.nom.toLowerCase()
                            )}`}
                          </Col>
                          <Col
                            sm="auto"
                            className="d-flex justify-content-end mx-2 px-4"
                            style={{ fontSize: ".60rem", width: "fit-content" }}
                          >
                            {moment(item.updated_at).fromNow()}
                          </Col>
                        </Row>
                        <Row>
                          <Col>{upperFirst(item.detail)}</Col>
                        </Row>
                        <Row className="d-flex justify-content-end p-2">
                          <Col
                            sm="auto  d-flex justify-content-start"
                            style={{ width: "fit-content", padding: "0" }}
                          >
                            <Button
                              style={{}}
                              className="btn-refuser p-0 px-2 py-1"
                              variant="outline-danger"
                              onClick={() =>
                                ouvrirRefuser(item.id_target, item.id)
                              }
                            >
                              Refuser
                            </Button>
                          </Col>
                          <Col
                            sm="auto "
                            style={{ width: "fit-content", padding: "0" }}
                            className="d-flex align-items-end mx-1"
                          >
                            {/* {item.id_target} */}
                            <Button
                              className="btn-accepter p-0 px-2 py-1"
                              variant="outline"
                              onClick={() =>
                                accepterHandle(item.id_target, item.id)
                              }
                            >
                              Se connecter
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : item.type === "INVITATION_ACCEPTEE" ? (
                    <Row className="d-flex align-items-center">
                      <Col
                        sm="auto"
                        style={{ width: "fit-content", marginRight: "7px" }}
                        className="p-0  photo-profil-contact d-flex align-items-center"
                      >
                        <Avatar
                          id="photoProfil"
                          className=""
                          style={{}}
                          src={item.participant_emeteur.photo}
                        ></Avatar>
                      </Col>
                      <Col sm="" className="p-1">
                        <Row>
                          <Col>
                            <b>{`${upperFirst(
                              item.participant_emeteur.prenom.toLowerCase()
                            )} ${upperFirst(
                              item.participant_emeteur.nom.toLowerCase()
                            )}`}</b>
                          </Col>
                          <Col
                            sm="auto"
                            className="d-flex justify-content-end px-4"
                            style={{ fontSize: ".60rem", width: "fit-content" }}
                          >
                            {moment(item.updated_at).fromNow()}
                          </Col>
                        </Row>
                        <Row>
                          <Col>{upperFirst(item.detail)}</Col>
                        </Row>
                        {/* <Row>
                                                    {item.id_target}
                                                </Row> */}

                        <Row className="d-flex justify-content-end px-2">
                          <Col
                            sm="auto  d-flex justify-content-start"
                            style={{ width: "fit-content", padding: "0" }}
                          >
                            <Button
                              className="py-1 mx-3 px-2 py-1"
                              variant="outline"
                              onClick={() =>
                                messageHandle(
                                  item.participant_emeteur.nom,
                                  item.participant_emeteur.prenom,
                                  item.participant_emeteur.fonction,
                                  item.id_target,
                                  item.participant_emeteur.photo,
                                  item.participant_emeteur.organisation.nom,
                                  item.participant_emeteur.organisation.logo,
                                  item.room,
                                  item.id,
                                  item.participant_emeteur.from_structure
                                )
                              }
                            >
                              Message
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : item.type === "MESSAGE" ? (
                    <>
                      <Col
                        sm="auto"
                        style={{ width: "fit-content" }}
                        className="p-0 mx-2 photo-profil-contact d-flex align-items-center"
                      >
                        <Avatar
                          id="photoProfil"
                          className=""
                          src={item.participant_emeteur.photo}
                          style={{}}
                        />
                      </Col>

                      <Col className="p-1">
                        <Row className="">
                          <Col className="noms-participant">
                            {`${upperFirst(
                              item.participant_emeteur.prenom.toLowerCase()
                            )} ${upperFirst(
                              item.participant_emeteur.nom.toLowerCase()
                            )}`}
                          </Col>
                          <Col
                            sm="auto"
                            className="d-flex justify-content-end mx-2 "
                            style={{ fontSize: ".60rem", width: "fit-content" }}
                          >
                            {moment(item.updated_at).fromNow()}
                          </Col>
                        </Row>
                        {/* <Row>
                                                        {item.id_target}
                                                    </Row> */}
                        <Row>
                          <Col>{upperFirst(item.detail)}</Col>
                        </Row>
                        <Row className="d-flex justify-content-end px-2">
                          <Col
                            sm="auto  d-flex justify-content-start"
                            style={{ width: "fit-content", padding: "0" }}
                          >
                            <Button
                              className="mx-3 px-2 py-1"
                              variant="outline"
                              onClick={() =>
                                messageHandle(
                                  item.participant_emeteur.nom,
                                  item.participant_emeteur.prenom,
                                  item.participant_emeteur.fonction,
                                  item.id_target,
                                  item.participant_emeteur.photo,
                                  item.participant_emeteur.organisation.nom,
                                  item.participant_emeteur.organisation.logo,
                                  item.room,
                                  item.id,
                                  item.participant_emeteur.from_structure
                                )
                              }
                            >
                              Message
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : item.type === "DEMANDE_RENDEZ_VOUS" ? (
                    <>
                      <Col
                        onClick={() => rdvHandle(item.id, item)}
                        sm="auto"
                        style={{ width: "fit-content" }}
                        className="p-0 mx-2 photo-profil-contact d-flex align-items-center"
                      >
                        <Avatar
                          id="photoProfil"
                          className=""
                          src={item.participant_emeteur.photo}
                          style={{}}
                        />
                      </Col>

                      <Col
                        className="p-1"
                        onClick={() => rdvHandle(item.id, item)}
                      >
                        <Row className="">
                          <Col className="noms-participant">
                            {`${upperFirst(
                              item.participant_emeteur.prenom.toLowerCase()
                            )} ${upperFirst(
                              item.participant_emeteur.nom.toLowerCase()
                            )}`}
                          </Col>
                          <Col
                            sm="auto"
                            className="d-flex justify-content-end mx-2 "
                            style={{ fontSize: ".60rem", width: "fit-content" }}
                          >
                            {moment(item.updated_at).fromNow()}
                          </Col>
                        </Row>
                        <Row>
                          <Col>{item.detail}</Col>
                        </Row>
                        {/* <Row className='d-flex justify-content-end px-2'>
                                                            <Col sm='auto  d-flex justify-content-start' style={{ width: 'fit-content', padding: '0' }}>
                                                                <Button className='btn-refuser' variant='outline-danger' >Refuser</Button>
                                                            </Col>
                                                            <Col sm='auto ' style={{ width: 'fit-content', padding: '0' }} className='d-flex align-items-end mx-1'>
                                                                <Button className='btn-accepter' variant='outline-success'  >Accepter</Button>
                                                            </Col>
                                                        </Row> */}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col
                        onClick={() => rdvHandle(item.id)}
                        sm="auto"
                        style={{ width: "fit-content" }}
                        className="p-0 mx-2 photo-profil-contact d-flex align-items-center"
                      >
                        <Avatar
                          id="photoProfil"
                          className=""
                          src={item.participant_emeteur.photo}
                          style={{}}
                        />
                      </Col>

                      <Col
                        className="p-1"
                        onClick={() => rdvHandle(item.id, null)}
                      >
                        <Row className="">
                          <Col className="noms-participant">
                            {`${upperFirst(
                              item.participant_emeteur.prenom
                            )} ${upperFirst(item.participant_emeteur.nom)}`}
                          </Col>
                          <Col
                            className="d-flex justify-content-end mx-2 "
                            style={{ fontSize: ".60rem", width: "fit-content" }}
                          >
                            {moment(item.updated_at).fromNow()}
                          </Col>
                        </Row>
                        <Row>
                          <Col>{item.detail}</Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
              ))
          ) : (
            <p
              className="h4"
              style={{
                fontFamily: PoliceDefaut,
                textAlign: "center",
                color: "#17418d",
                fontSize: "150%",
              }}
            >
              Pas de nouvelle notification
            </p>
          )}
        </Row>
      )}
    </Container>
  );
};

export default NotificationsPage;
