import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Chemin from "../components/Chemin";
import Navigation from "../components/Navigation";

const CompteDeleted = () => {
  const location = useLocation();

  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter((item) => item.nom === "Accueil");
  breadcrumbs_temp.push({ url: location.pathname, nom: "Clôturer son compte" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
  }, []);

  return (
    <Container fluid>
      <Row>
        <Navigation
          button={false}
          titre={"Clôturer son compte"}
          // photo={monProfil.photo_path}
          retour="dashboard"
          menus={false}
        />
      </Row>
      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      <form className="form-suppression" style={{ fontSize: "120%" }}>
        <Row>
          <Col>Votre compte a été clôturé</Col>
        </Row>
        {/* <Row className='d-flex justify-content-center mt-3'>
                              <Button onClick={(e) => closeTab(e)} style={{ borderRadius: '50px', backgroundColor: '#17418d', width: '100px' }} className='' variant="primary" type="">
                                    Fermer
                             </Button>
                  </Row> */}
      </form>
    </Container>
  );
};

export default CompteDeleted;
