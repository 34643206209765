import axios from "axios";
import { URL } from "./api";
import { participantsRedux } from "./evenementsServices";

export const profilRedux = (id_participant, token) => {
  return axios.get(URL + "/api/participants/" + id_participant, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const contactsRedux = (token) => {
  return axios.get(URL + "/api/contacts?type=contacts", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};

export const demandesRedux = (token) => {
  return axios.get(URL + "/api/contacts?type=demandes", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const envoyerDemandeRedux = (token, id_participant, id_evenement) => {
  return axios.post(
    URL + "/api/contacts",
    {
      participant: id_participant,
      evenement: id_evenement,
    },
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};
export const accepterDemandeRedux = (token, id_demande) => {
  return axios.put(
    URL + "/api/contacts",
    {
      operation: "accepter",
      demande_id: id_demande,
    },
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};
export const annulerDemandeRedux = (token, id_demande) => {
  return axios.put(
    URL + "/api/contacts",
    {
      operation: "annuler",
      demande_id: id_demande,
    },
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};
export const refuserDemandeRedux = (token, id_demande) => {
  return axios.put(
    URL + "/api/contacts",
    {
      operation: "refuser",
      demande_id: id_demande,
    },
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};
export const invitationsRedux = (token) => {
  return axios.get(URL + "/api/contacts?type=invitations", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const NotificationsRedux = (token) => {
  return axios.get(URL + "/api/notifications", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const centreInterets = (token) => {
  return axios.get(URL + "/api/centre-interet", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};

export const listMessage = async (token, id = null) => {
  let response = [];
  try {
    if (id != null) {
      response = await axios.get(
        URL + "/api/participant-conversation/" + id + "/",
        {
          headers: {
            Authorization: "JWT " + token,
          },
        }
      );
    } else {
      response = await axios.get(URL + "/api/participant-conversation/", {
        headers: {
          Authorization: "JWT " + token,
        },
      });
    }
    // console.log('Les messages :', response.data)
    return response.data;
  } catch (error) {
    return response;
  }
};

export const configInfos = (token, dispatch, navlink, page) => {
  axios
    .get(`${URL}/api/configurations`, {
      headers: {
        Authorization: "JWT " + token,
      },
    })
    .then((reponse) => {
      dispatch({
        type: "Profil/addConfig",
        payload: reponse.data,
      });
      // console.log('Config :', reponse.data)
      navlink(`/${page}`);
    });
};

export const chatSelect = async (
  chat,
  dispatch,
  navlink,
  page,
  token,
  circular
) => {
  circular(true);
  await dispatch({
    type: "Profil/addChat",
    payload: chat.contact,
  });
  await dispatch({
    type: "Profil/idChat",
    payload: chat.id,
  });

  await axios
    .get(`${URL}/api/evenement-conversation/${chat.id}`, {
      headers: {
        Authorization: "JWT " + token,
      },
    })
    .then((response) => {
      dispatch({
        type: "Profil/evenentChat",
        payload: response.data,
      });
    })
    .catch((erreur) => {
      // console.log(erreur)
      circular(false);
    });

  // console.log(token)
  await navlink(page);
  circular(false);
};

export const creerRdv = (
  token,
  idEvenement,
  debut,
  fin,
  sujet,
  description,
  lieu,
  idStand,
  idDestinateur
) => {
  return axios.post(
    URL + "/api/participants/" + idEvenement + "/programmes",
    {
      heure_deb: debut,
      heure_fin: fin,
      titre: sujet,
      description: description,
      lieu: lieu,
      stand: idStand,
      destinateur: idDestinateur,
      evenement: idEvenement,
    },
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};

export const validerRdv = (idProgramme, idParticipant, token, choix) => {
  return axios.post(
    `${URL}/api/rendez_vous/participer`,
    {
      programme: idProgramme,
      participant: idParticipant,
      choix: choix,
    },
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};

export const pagesContent = async () => {
  return await axios.get(`${URL}/api/configurations-pages`);
};

export const refreshToken = (token) => {
  return axios.post(`${URL}/token/refresh`, {
    refresh: token,
  });
};
