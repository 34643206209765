import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Modal,
  Row,
} from "react-bootstrap";
import "../styles/login.css";
import { NavLink } from "react-router-dom";

import { CircleContext, LoggedContext, NotifContext } from "../Context";
import LOGO from "../assets/img/logo.png";

//Test login
import { useDispatch, useSelector } from "react-redux";
import { connexionRedux, getParametres } from "../redux/authService";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import { pictos } from "../assets/img/pictos";
import { recupPassword } from "./fonctions";

import POSTER from "../assets/img/poster_neo.png";
import {
  NotificationsRedux,
  contactsRedux,
  demandesRedux,
  invitationsRedux,
  pagesContent,
  profilRedux,
} from "../redux/profilService";
import jwt_decode from "jwt-decode";
import { evenementsRedux } from "../redux/evenementsServices";
import { ReportOutlined } from "@material-ui/icons";

import MuiAlert from "@mui/material/Alert";
import { Input } from "@mui/joy";
import { resetEvenements } from "../redux/evenementsSlice";
import ErrorPopup from "../components/ErrorPopup";
import textConnect from "../textConnect.json";
import { PoliceDefaut } from "../helpers/contants";

const Login = () => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);

  //States redux

  const utilisateur = useSelector((state) => state.profil.profil);
  const id_user = useSelector((state) => state.user.idUser);
  const erreur = useSelector((state) => state.user.erreur);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [circular, setCircular] = useState(false)

  const [allowConnect, setAllowConnect] = useState(true);

  const [souvenir, setSouvenir] = useState(false);

  const loggedContext = useContext(LoggedContext);
  const circleContext = useContext(CircleContext);
  const notifContext = useContext(NotifContext);

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  function connexion(e, email, password) {
    e.preventDefault();
    localStorage.setItem("is_logged", JSON.stringify(souvenir));
    dispatch(resetEvenements());
    // dispatch(
    //     {
    //         type: 'evenements/addEvenementSelect',
    //         payload: {}
    //     }
    // )
    circleContext.setCircle(true);

    // connexionRedux(email, password, dispatch, loggedContext, souvenir, circleContext)

    connexionRedux(email, password)
      .then(async (reponse) => {
        await dispatch({
          type: "user/addRefresh",
          payload: reponse.data.refresh,
        });

        await dispatch({
          type: "user/addAccess",
          payload: reponse.data.access,
        });
        await dispatch({
          type: "user/addId",
          payload: jwt_decode(reponse.data.access).participant_id,
        });
        await dispatch({
          type: "user/addErreur",
          payload: false,
        });

        await profilRedux(
          jwt_decode(reponse.data.access).participant_id,
          reponse.data.access
        ).then((response) => {
          dispatch({
            type: "Profil/addProfil",
            payload: response.data,
          });
        });

        await loggedContext.setLogged(true);
        // localStorage.setItem("is_logged", true)
        // localStorage.setItem("is_logged", JSON.stringify(souvenir))
        await circleContext.setCircle(false);
      })
      .catch((erreur) => {
        if (erreur.response) {
          // console.log(erreur.response.status)

          if (erreur.response.status === 401) {
            //Erreur mot de passe ou nom d'utilisateur
            setMsgError("Nom d'utilisateur ou mot de passe incorrect");
            setOpenError(true);
          }
          if (erreur.response.status === 400) {
            setMsgError("Veuillez remplir tous les champs");
            setOpenError(true);
          }

          if (erreur.response.status > 401) {
            setMsgError(textConnect.error);

            setOpenError(true);
          }
          // else {
          //     setMsgError('Vérifiez votre connexion internet')
          //     setOpenError(true)
          // }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        // console.log('data error :', erreur.response.data)
        // console.log('data status :', erreur.response.status)
        // console.log('data headers :', erreur.response.headers)
        // console.log('data reponse :', erreur.response)

        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        circleContext.setCircle(false);
        loggedContext.setLogged(false);
      });
  }

  const [showPassword, setShowPassword] = useState(false);
  const [messagePassword, setMessagePassword] = useState(false);

  const [mailRecup, setMailRecup] = useState("");
  const [circleRecup, setCircleRecup] = useState(false);

  const handleClosePassword = () => {
    setShowPassword(false);
    setMessagePassword(false);
    setMailRecup("");
  };
  const handleShowPassword = () => setShowPassword(true);

  const [statePassword, setStatePassword] = useState(false);

  function passwordVisible() {
    if (statePassword) {
      document.getElementById("password").setAttribute("type", "password");
      setStatePassword(false);
    } else {
      document.getElementById("password").setAttribute("type", "text");
      setStatePassword(true);
    }
  }

  useEffect(() => {
    pagesContent()
      .then((reponse) => {
        dispatch({
          type: "Profil/addTextContent",
          payload: reponse.data,
        });
        setLoader(false);
      })
      .catch((erreur) => {
        // setLoader(false)
        if (!netStatus) {
          setMsgError1(textConnect.internet);
          setOpenError1(true);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        // if (erreur.request) {
        //     if (erreur.request.status === 0) {
        //         console.log(erreur.request)
        //         setMsgError1('Vérifiez votre connexion internet')
        //         setOpenError1(true)

        //     }
        // }
        if (erreur.response) {
          // if (erreur.response.status === 401) {
          //     refreshToken(refresh_token).then(
          //         async (reponse) => {
          //             dispatch(
          //                 {
          //                     type: 'user/addRefresh',
          //                     payload: reponse.data.refresh
          //                 }
          //             );
          //             dispatch(
          //                 {
          //                     type: 'user/addAccess',
          //                     payload: reponse.data.access
          //                 }
          //             )
          //             console.log('token refresh...')
          //             selectEvent(token, id)
          //         })
          // }
          if (erreur.response.status !== 401) {
            setMsgError1(textConnect.error);
            setOpenError1(true);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      });
  }, []);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const texteIntro = useSelector((state) =>
    state.profil.contenusTexte !== undefined
      ? state.profil.contenusTexte.message_introduction
      : ""
  );
  const contenusBO = useSelector((state) => state.profil.contenusTexte);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [openError1, setOpenError1] = useState(false);
  const [msgError1, setMsgError1] = useState("");

  const closeErrorHandle = () => {
    setOpenError(false);
    setOpenError1(false);
  };
  const passwordRecup = (e, mail) => {
    setCircleRecup(true);

    recupPassword(e, mail)
      .then((reponse) => {
        setMessagePassword(true);
        setCircleRecup(false);
        setMailRecup("");
      })
      .catch((erreur) => {
        if (!netStatus) {
          setMsgError1(textConnect.internet);
          setOpenError1(true);
        }
        if (erreur.response) {
          // if (erreur.response.status === 401) {
          //     refreshToken(refresh_token).then(
          //         async (reponse) => {
          //             dispatch(
          //                 {
          //                     type: 'user/addRefresh',
          //                     payload: reponse.data.refresh
          //                 }
          //             );
          //             dispatch(
          //                 {
          //                     type: 'user/addAccess',
          //                     payload: reponse.data.access
          //                 }
          //             )
          //             console.log('token refresh...')
          //             selectEvent(token, id)
          //         })
          // }
          if (erreur.response.status !== 401) {
            setMsgError1(textConnect.error);
            setOpenError1(true);
          }
        }
        setCircleRecup(false);
      })
      .finally(() => {
        setCircleRecup(false);
      });
  };
  return loader ? (
    <>
      <LinearProgress />
      <ErrorPopup
        message={msgError1}
        open={openError1}
        close={() => closeErrorHandle()}
      />
    </>
  ) : (
    <>
      <ErrorPopup
        message={msgError1}
        open={openError1}
        close={() => closeErrorHandle()}
      />
      {/* Récupération mot de passe */}
      <Modal
        onHide={() => handleClosePassword()}
        show={showPassword}
        style={{ fontFamily: PoliceDefaut }}
      >
        <Modal.Header style={{ color: "white", backgroundColor: "#17418d" }}>
          <h4>Récupération du mot de passe</h4>
        </Modal.Header>
        <form onSubmit={(e) => passwordRecup(e, mailRecup)}>
          <Modal.Body>
            {messagePassword ? (
              <p>Le mot de passe a été envoyé à l’email de votre compte.</p>
            ) : (
              <FormControl
                className="mb-3"
                style={{
                  borderRadius: "50px",
                  color: "#17418d",
                  backgroundColor: "#e4e4e4",
                }}
                onChange={(e) => setMailRecup(e.target.value)}
                type="email"
                placeholder="Saisissez votre adresse mail"
              />
            )}

            <div className="d-flex justify-content-end">
              {circleRecup ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  className="btn-recup"
                  style={{ backgroundColor: "#0e71b4" }}
                  disabled={mailRecup === "" ? true : false}
                >
                  Récupérer
                </Button>
              )}
              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                style={{}}
                onClick={handleClosePassword}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <Container
        fluid
        className="d-flex align-items-center login min-vh-100"
        style={{ position: "" }}
      >
        <video
          id="login-video"
          className="video-login "
          src={contenusBO !== undefined ? contenusBO.fichier : ""}
          preload="auto"
          muted
          type="video/mp4"
          autoPlay={true}
          loop={true}
          playsInline
          poster=""
        >
          impossible de lire la vidéo
        </video>
        <Row
          className="d-flex align-items-center"
          style={{ margin: "auto", minHeight: "100vh", minWidth: "90vw" }}
        >
          {/* <Row className='m-auto px-2 mb-2 mt-2' style={{ color: 'black', fontSize: '.85rem', textAlign: 'center', opacity: openError ? '1' : '0', width: 'fit-content', height: 'fit-content', backgroundColor: 'gray', position: 'absolute', right: '0', left: '47%' }}>
                                    {msgError}
                                    </Row> */}

          {/* transform: 'translate(-50%, -50%)' */}
          <Row
            className="logo-login mt-2"
            style={{ marginTop: "", display: "" }}
          >
            <img src={LOGO} alt="" className="" style={{ zIndex: "600" }} />
          </Row>

          <div
            className=""
            style={{ zIndex: "600", minHeight: "", width: "100%" }}
          >
            <Container>
              <Row
                className="text-login text-center m-auto mb-3"
                style={{ border: "none" }}
              >
                {texteIntro === undefined ? (
                  <></>
                ) : (
                  <p
                    className="p-0"
                    dangerouslySetInnerHTML={{
                      __html: texteIntro,
                    }}
                  />
                )}
              </Row>
              <Row className="d-flex justify-content-center">
                <form
                  style={{
                    width: "500px",
                    zIndex: "600",
                    position: "relative",
                  }}
                  onSubmit={(e) => connexion(e, email, password)}
                >
                  <Row className="mb-4">
                    <Input
                      className="usernameContainer"
                      style={{}}
                      type="email"
                      id="username"
                      placeholder="E-mail"
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        if (e.target.value !== "") {
                          setAllowConnect(false);
                        } else {
                          setAllowConnect(true);
                        }
                      }}
                      sx={{ borderRadius: "50px", fontFamily: PoliceDefaut }}
                    />
                  </Row>
                  <Row className="mb-3">
                    <Input
                      className="passwordContainer "
                      type="password"
                      id="password"
                      endDecorator={
                        password !== "" ? (
                          <img
                            style={{ cursor: "pointer" }}
                            title="Affcher/Masquer le mot de passe"
                            className=""
                            src={pictos.voir_icone}
                            alt=""
                            width="25px"
                            onClick={() => passwordVisible()}
                          />
                        ) : (
                          <p style={{}}></p>
                        )
                      }
                      placeholder="Mot de passe"
                      onChange={(e) => {
                        setPassword(e.target.value);

                        if (e.target.value !== "") {
                        }
                      }}
                      sx={{ borderRadius: "50px", fontFamily: PoliceDefaut }}
                    />
                  </Row>
                  <label
                    className="mt-2 error2 m-auto"
                    htmlFor=""
                    style={{
                      zIndex: "600",
                      color: "black",
                      fontSize: ".85rem",
                      textAlign: "center",
                      opacity: openError ? "1" : "0",
                      width: "fit-content",
                      height: "fit-content",
                      backgroundColor: "gray",
                    }}
                  >
                    {msgError}
                  </label>

                  <Row
                    className=" d-flex justify-content-center mb-3 "
                    style={{ color: "white", width: "", margin: "auto" }}
                  >
                    <Col
                      xs={1}
                      style={{ width: "" }}
                      className=" p-0 d-flex align-items-center justify-content-end"
                    >
                      <input
                        type="checkbox"
                        name="souvenir"
                        id="souvenir"
                        onChange={(e) => {
                          dispatch({
                            type: "user/addSouvenir",
                            payload: e.target.checked,
                          });
                        }}
                      />
                    </Col>
                    <Col
                      sm={"auto"}
                      className="p-0 d-flex align-items-center"
                      style={{ width: "fit-content" }}
                    >
                      <label
                        htmlFor="souvenir"
                        className=""
                        style={{ paddingLeft: "5px" }}
                      >
                        Se souvenir de moi
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    {circleContext.circle ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress style={{ color: "red" }} />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        className="btn-connexion"
                        disabled={allowConnect}
                      >
                        Je me connecte{" "}
                      </Button>
                    )}
                  </Row>
                  <div className="access-container">
                    <p className="access-rule">
                      Vos codes d'accès ont été créés automatiquement lors de
                      votre inscription à la manifestation et adressés par mail.
                      Vous ne retrouvez pas ces accès, il vous suffit de cliquer
                      sur {`"Mot de passe oublié"`}
                    </p>
                  </div>
                  <Row className="password-oublie my-4">
                    <NavLink onClick={() => handleShowPassword()}>
                      Mot de passe oublié
                    </NavLink>
                  </Row>

                  <Row
                    className="m-auto px-2 mb-2 mx-1 error1"
                    style={{
                      zIndex: "600",
                      color: "black",
                      fontSize: ".85rem",
                      textAlign: "center",
                      opacity: openError ? "1" : "0",
                      width: "300px",
                      height: "fit-content",
                      backgroundColor: "gray",
                      position: "absolute",
                      right: "0",
                      top: "0",
                      left: "100%",
                    }}
                  >
                    <div className="p-0">{msgError}</div>
                  </Row>
                </form>
              </Row>
            </Container>
          </div>
          <footer
            className="d-flex align-items-end footer-login"
            style={{ marginTop: "auto" }}
          >
            <Container>
              <Row>
                <Col
                  className="mt-4 mb-1"
                  style={{
                    width: "",
                    fontSize: ".75rem",
                    textAlign: "center",
                    fontFamily: PoliceDefaut,
                    zIndex: "700",
                    color: "white",
                  }}
                >
                  &copy; 2023 Agence BEC •{" "}
                  <NavLink
                    className="mentions-legales"
                    style={{ textDecoration: "none" }}
                    to={"/conditionsLegales/0"}
                  >
                    {" "}
                    Mentions légales
                  </NavLink>{" "}
                  •{" "}
                  <NavLink
                    className="mentions-legales"
                    style={{ textDecoration: "none" }}
                    to={"/copyright/0"}
                  >
                    Conditions d'utilisation
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </footer>
        </Row>
      </Container>
    </>
  );
};

export default Login;
