import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "../styles/partenaireExpo.css";
import "../styles/programme.css";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge } from "@mui/joy";
import { pictos } from "../assets/img/pictos";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toLower, upperFirst } from "lodash";
import Navigation from "../components/Navigation";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  contactsRedux,
  demandesRedux,
  envoyerDemandeRedux,
  invitationsRedux,
  refreshToken,
} from "../redux/profilService";
import ErrorPopup from "../components/ErrorPopup";
import {
  partenairesRedux,
  participantSelectRedux,
  participantsRedux,
  programmeSelectRedux,
} from "../redux/evenementsServices";
import { CircularProgress } from "@material-ui/core";
import { couleurTheme, pictosCategorie } from "./fonctions";
import Chemin from "../components/Chemin";
import textConnect from "../textConnect.json";
import { PoliceDefaut } from "../helpers/contants";

const PartenaireDetail = (props) => {
  const param = useParams();
  const partenaireSelected = useSelector(
    (state) => state.evenements.exposantSelect
  );
  // const programmeSelected = useSelector(state => state.evenements.programmeSelect)
  // const intervenants = useSelector(state => state.evenements.exposantSelect.intervenants)
  const equipe = useSelector((state) => state.evenements.exposantSelect.equipe);
  const evenementSelected = useSelector(
    (state) => state.evenements.evenementSelect
  );

  const monProfil = useSelector((state) => state.profil.profil);
  const accessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector((state) => state.user.refresh);

  const dispatch = useDispatch();

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  const [open2, setOpen2] = useState(false);

  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const closeErrorHandle = () => {
    setOpenError(false);
  };
  const location = useLocation();
  const navigationPage = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const participantSelect = (token, id) => {
    setLoading2(true);
    participantsRedux(token, evenementSelected.id)
      .then((reponse) => {
        dispatch({
          type: "evenements/addParticipants",
          payload: reponse.data,
        });

        participantSelectRedux(token, id, evenementSelected.id).then(
          (reponse) => {
            dispatch({
              type: "evenements/addParticipantSelect",
              payload: reponse.data,
            });
            navigationPage(`/CompteParticipant/${id}/0`);
            setLoading2(false);
            window.scrollTo(0, 0);
          }
        );
      })
      .catch((erreur) => {
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError("Une erreur s'est produite.");
            setOpenError(true);
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        setLoading2(false);
      });
  };
  const [openEnvoyerDemande, setOpenEnvoyerDemande] = useState(false);
  const [selectProfil, setSelectProfil] = useState("");
  const [circle, setCircle] = useState(false);
  const ouvrirEnvoyer = (id) => {
    setSelectProfil(id);
    setOpenEnvoyerDemande(true);
  };
  const handleEnvoiDemande = () => {
    setCircle(true);
    envoyerDemandeRedux(accessToken, selectProfil, evenementSelected.id)
      .then(async (reponse) => {
        // console.log('demande envoyé :', reponse.data)
        await partenairesRedux(accessToken, evenementSelected.id).then(
          (reponse) => {
            dispatch({
              type: "evenements/addExposant",
              // payload: reponse.data.filter()[0]
              payload: reponse.data,
            });
            dispatch({
              type: "evenements/addExposantSelect",
              // payload: reponse.data.filter()[0]
              payload: reponse.data.filter(
                (value) => value.id === partenaireSelected.id
              )[0],
            });
          }
        );
        setCircle(false);
        setOpenEnvoyerDemande(false);
      })
      .catch((erreur) => {
        setCircle(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              handleEnvoiDemande();
            });
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };

  const programSelect = (token, id) => {
    setLoading(true);
    // participantsList(token, evenementSelected.id)
    programmeSelectRedux(token, id)
      .then((reponse) => {
        dispatch({
          type: "evenements/addProgrammeSelect",
          payload: reponse.data,
        });
        // window.open('/programmeDetail', '_top')
        navigationPage("/programme/" + id + "/0");
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((erreur) => {
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              programSelect(token, id);
            });
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };
  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp =
    param.premium === "1"
      ? breadCrumbs.filter(
          (item) =>
            item.nom === "Accueil" && item.url.split("/")[1] !== "planStand"
        )
      : breadCrumbs.filter(
          (item) =>
            item.url.split("/")[1] !== "partenaire" &&
            item.url.split("/")[1] !== "programme" &&
            item.url.split("/")[1] !== "planStand"
        );
  breadcrumbs_temp.push({
    url: location.pathname,
    nom: upperFirst(toLower(partenaireSelected.nom)),
  });
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
    console.log(breadcrumbs_temp);
  }, []);

  const [indexPart, setIndexPart] = useState([]);
  useEffect(() => {
    if (selectProfil !== "") {
      setIndexPart((old) => [...old, selectProfil.toString()]);
    }
  }, [selectProfil]);
  return (
    <Container fluid>
      <Modal
        className="d-flex align-items-center"
        show={openEnvoyerDemande}
        onHide={() => setOpenEnvoyerDemande(false)}
        style={{ fontFamily: PoliceDefaut }}
      >
        <Modal.Header
          closeVariant="white"
          closeButton
          style={{ color: "white", backgroundColor: "#17418d" }}
        >
          <h4>Confirmation</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment envoyer une demande ?
            <div className="d-flex justify-content-end mt-3">
              {circle ? (
                <CircularProgress />
              ) : (
                <Button
                  className="btn-recup"
                  onClick={() => handleEnvoiDemande()}
                >
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenEnvoyerDemande(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />
      <Row>
        <Navigation
          button={true}
          titre={"Partenaires exposants"}
          icone={pictos.partenaires_rond_icone}
          photo={monProfil.photo_path}
          evenement={evenementSelected.photo}
          retour="dashboard"
          profile={false}
          selected={2}
          open={(!open2).toString()}
          menus={true}
        />
      </Row>
      {/* <Row className='p-0'>
                <Breadcrumb
                    third={param.premium === '1' ? false : true}
                    focus={param.premium === '1' ? partenaireSelected.nom : 'Partenaires exposants'}
                    location={param.premium === '1' ? location : '/partenaires'}
                    subfocus={partenaireSelected.nom}
                    sublocation={location}
                />
            </Row> */}
      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      <Row>
        <Row style={{}} className="infos-profil">
          <div className="container-gauche">
            <Container>
              <Row
                className="d-flex align-items-center"
                style={{ marginBottom: "10px" }}
              >
                <Col
                  xs={100}
                  className=" p-0 d-flex align-items-center"
                  style={{ height: "100px" }}
                >
                  {partenaireSelected.coordonnee.contact.site === "" ||
                  partenaireSelected.coordonnee.contact.site === undefined ? (
                    <img
                      className=""
                      src={
                        partenaireSelected.logo === ""
                          ? pictos.entreprise_icone
                          : partenaireSelected.logo
                      }
                      alt={partenaireSelected.nom}
                      style={{ maxWidth: "150px", maxHeight: "90px" }}
                    />
                  ) : (
                    <a
                      href={`${partenaireSelected.coordonnee.contact.site}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className=""
                        src={
                          partenaireSelected.logo === ""
                            ? pictos.entreprise_icone
                            : partenaireSelected.logo
                        }
                        alt={partenaireSelected.nom}
                        style={{ maxWidth: "150px", maxHeight: "90px" }}
                      />
                    </a>
                  )}
                </Col>
                <Col className="nom-profil1 ">
                  <Row className="d-flex align-items-center">
                    <Col
                      className="profil-nom p-0 text-capitalize"
                      style={{ textTransform: "capitalize" }}
                    >
                      {upperFirst(toLower(partenaireSelected.nom))}
                    </Col>
                    <Col
                      sm={2}
                      className=" p-0 nom-profil1 d-flex justify-content-end"
                      style={{ width: "fit-content" }}
                    >
                      {partenaireSelected.stand.code === "" ||
                      partenaireSelected.stand.code === undefined ? (
                        <></>
                      ) : (
                        <div className="d-flex align-items-center">
                          <img
                            title="Voir le stand"
                            onClick={() =>
                              navigationPage(
                                partenaireSelected.stand.id === undefined ||
                                  partenaireSelected.stand.id === ""
                                  ? ""
                                  : "/planStand/" + partenaireSelected.stand.id
                              )
                            }
                            src={pictos.lieu_icone}
                            alt=""
                            style={{
                              height: "25px",
                              width: "auto",
                              cursor: "pointer",
                            }}
                          />
                          <div className="mt-2 d-flex align-items-center h6 ">{`${upperFirst(
                            toLower(partenaireSelected.stand.code)
                          )}`}</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col
                      className="p-0"
                      style={{ textTransform: "capitalize" }}
                    >
                      {upperFirst(toLower(partenaireSelected.domaine))}.
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                className="coordonnees-container"
                style={{ margin: "", textAlign: "" }}
              >
                <Col>
                  <Row className="coordonnee-info">
                    <Col className="p-0 coordonnees-titre mt-2">Contact</Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      <Avatar
                        src={partenaireSelected.coordonnee.contact.photo}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="p-0"
                      sm="auto"
                      style={{ textTransform: "capitalize" }}
                    >
                      <b>{`${upperFirst(
                        toLower(partenaireSelected.coordonnee.contact.nom)
                      )}`}</b>
                    </Col>
                  </Row>
                  <Row xs={""} className="mb-2">
                    <Col
                      className="p-0"
                      sm="auto"
                      style={{ textTransform: "capitalize" }}
                    >
                      {`${upperFirst(
                        toLower(partenaireSelected.coordonnee.contact.fonction)
                      )}`}
                    </Col>
                  </Row>
                  {partenaireSelected.coordonnee.contact.telephone === "" ||
                  partenaireSelected.coordonnee.contact.telephone === null ||
                  partenaireSelected.coordonnee.contact.telephone ===
                    undefined ? null : (
                    <Row className="d-flex align-items-center">
                      <Col className="icone p-0" sm="auto">
                        <img src={pictos.tel_icone} alt="" />
                      </Col>
                      <Col className="p-0">
                        {`${partenaireSelected.coordonnee.contact.telephone}`}
                      </Col>
                    </Row>
                  )}
                  {partenaireSelected.coordonnee.contact.email === "" ||
                  partenaireSelected.coordonnee.contact.email === null ||
                  partenaireSelected.coordonnee.contact.email ===
                    undefined ? null : (
                    <Row className="d-flex align-items-center">
                      <Col className="icone p-0" sm="auto">
                        <img src={pictos.mail_icone} alt="" />
                      </Col>
                      <Col className="p-0">
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#17418d",
                            wordBreak: "break-word",
                          }}
                          href={`mailto:${partenaireSelected.coordonnee.contact.email}`}
                        >
                          {partenaireSelected.coordonnee.contact.email}
                        </a>
                        {/* {`${partenaireSelected.coordonnee.contact.email}`} */}
                      </Col>
                    </Row>
                  )}
                  {partenaireSelected.coordonnee.contact.adresse.numero ===
                  undefined ? null : (
                    <Row
                      className="d-flex align-items-top"
                      style={{ wordBreak: "break-word" }}
                    >
                      <Col className="icone mt-2 p-0" sm="auto">
                        <img src={pictos.adresse_icone} alt="" />
                      </Col>
                      <Col
                        className="pt-2 p-0"
                        style={{ wordBreak: "break-word" }}
                      >
                        {`${partenaireSelected.coordonnee.contact.adresse.numero}`}{" "}
                        <br />{" "}
                        {`${partenaireSelected.coordonnee.contact.adresse.code_postal}, ${partenaireSelected.coordonnee.contact.adresse.ville}.`}
                      </Col>
                    </Row>
                  )}

                  <Row className="d-flex align-items-center mb-3">
                    {partenaireSelected.twitter !== "" &&
                    partenaireSelected.twitter !== null &&
                    partenaireSelected.twitter !== "null" ? (
                      <Col className="icone p-0 mt-2" sm="auto">
                        <div
                          className="icone mt-2"
                          style={{ display: "inline-block", width: "35px" }}
                        >
                          <NavLink
                            to={partenaireSelected.twitter}
                            target="_blank"
                          >
                            <img src={pictos.twitter_icone} alt="" />
                          </NavLink>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {partenaireSelected.linkedin !== "" &&
                    partenaireSelected.linkedin !== null &&
                    partenaireSelected.linkedin !== "null" ? (
                      <Col className="icone p-0 mt-2" sm="auto">
                        <div
                          className="icone mt-2"
                          style={{ display: "inline-block", width: "35px" }}
                        >
                          <NavLink
                            to={partenaireSelected.linkedin}
                            target="_blank"
                          >
                            <img src={pictos.linkedin_icone} alt="" />
                          </NavLink>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                  {/* <Row className='d-flex align-items-center'>
                                        <Col className='icone mt-2' sm='auto'>
                                            <div className='d-flex justify-content-center align-items-center' style={{ borderRadius: '50%', backgroundColor: '#17418d', width: '30px', height: '30px' }}>
                                                <Language style={{ color: 'white' }} fontSize='small' />
                                            </div>
                                        </Col>
                                        <Col className='px-4'>
                                            <a className='row' href={`http://${partenaireSelected.coordonnee.contact.site}`} target="_blank" rel="noopener noreferrer">{`${partenaireSelected.coordonnee.contact.site}`}</a>
                                        </Col>
                                    </Row> */}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="container-droite">
            <Container>
              <Row
                className="profil-coordonnees nom-profil2"
                style={{ paddingTop: "26px", paddingBottom: "26px" }}
              >
                <Row
                  className="d-flex align-items-center justify-content-end"
                  style={{ textTransform: "capitalize" }}
                >
                  <Col className="profil-nom p-0">
                    {upperFirst(toLower(partenaireSelected.nom))}
                  </Col>
                  <Col sm={2} className=" p-0  d-flex justify-content-end">
                    {partenaireSelected.stand.code === "" ||
                    partenaireSelected.stand.code === undefined ? (
                      <></>
                    ) : (
                      <div className="d-flex align-items-center">
                        <img
                          title="Voir le stand"
                          onClick={() =>
                            navigationPage(
                              partenaireSelected.stand.id === undefined ||
                                partenaireSelected.stand.id === ""
                                ? ""
                                : "/planStand/" + partenaireSelected.stand.id
                            )
                          }
                          src={pictos.lieu_icone}
                          alt=""
                          style={{
                            height: "25px",
                            width: "auto",
                            cursor: "pointer",
                          }}
                        />
                        <div className="mt-2 d-flex align-items-center h6 ">{`${upperFirst(
                          toLower(partenaireSelected.stand.code)
                        )}`}</div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="">
                  <Col className="p-0" style={{ textTransform: "capitalize" }}>
                    {upperFirst(toLower(partenaireSelected.domaine))}{" "}
                    {partenaireSelected.domaine === "" ? (
                      <div style={{ opacity: "0" }}>.</div>
                    ) : null}
                  </Col>
                </Row>
              </Row>

              {partenaireSelected.interventions.length ? (
                <Row className="coordonnee-info">
                  <div>
                    <Row className="coordonnees-titre-infos">
                      <Col className="my-2 p-0">Interventions</Col>
                    </Row>
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div>
                        {partenaireSelected.interventions.map((item, index) => (
                          <Row
                            key={index}
                            className="programme-item mb-3"
                            style={{ paddingLeft: "5px", margin: "" }}
                          >
                            <Col className="">
                              <Row className="d-flex align-items-center">
                                <Col
                                  sm="auto"
                                  className="programme-date"
                                  style={{
                                    backgroundColor: couleurTheme(
                                      item.categorie
                                    ),
                                    marginLeft: "10px",
                                  }}
                                >
                                  {item.heure_fin === "" ||
                                  item.heure_fin === undefined ||
                                  item.heure_fin === null
                                    ? item.heure_deb
                                    : item.heure_deb + " - " + item.heure_fin}
                                </Col>
                                <Col
                                  className="stand1 p-0"
                                  style={{ width: "" }}
                                >
                                  {item.categorie.for_room ? (
                                    (item.stand.id === "" ||
                                      item.stand.id === undefined) &&
                                    item.salle === "" ? null : (
                                      <div className="d-flex align-items-center">
                                        <img
                                          title={
                                            item.stand.id === undefined ||
                                            item.stand.id === ""
                                              ? ""
                                              : "Voir le stand"
                                          }
                                          onClick={() =>
                                            navigationPage(
                                              item.stand.id === undefined ||
                                                item.stand.id === ""
                                                ? ""
                                                : "/planStand/" + item.stand.id
                                            )
                                          }
                                          src={pictos.lieu_icone}
                                          alt=""
                                          style={{
                                            height: "25px",
                                            width: "auto",
                                            cursor:
                                              item.stand.id === undefined ||
                                              item.stand.id === ""
                                                ? "default"
                                                : "pointer",
                                          }}
                                        />
                                        <div className=" pt-1 d-flex align-items-center">{`${upperFirst(
                                          toLower(item.salle)
                                        )}`}</div>
                                      </div>
                                    )
                                  ) : item.stand.code === "" ||
                                    item.stand.code === undefined ? null : (
                                    <div className="d-flex align-items-center">
                                      <img
                                        title={
                                          item.stand.id === undefined ||
                                          item.stand.id === ""
                                            ? ""
                                            : "Voir le stand"
                                        }
                                        onClick={() =>
                                          navigationPage(
                                            item.stand.id === undefined ||
                                              item.stand.id === ""
                                              ? ""
                                              : "/planStand/" + item.stand.id
                                          )
                                        }
                                        src={pictos.lieu_icone}
                                        alt=""
                                        style={{
                                          height: "25px",
                                          width: "auto",
                                          cursor:
                                            item.stand.id === undefined ||
                                            item.stand.id === ""
                                              ? "default"
                                              : "pointer",
                                        }}
                                      />
                                      <div className=" pt-1 d-flex align-items-center">{`${upperFirst(
                                        toLower(item.stand.code)
                                      )}`}</div>
                                    </div>
                                  )}
                                </Col>
                                <img
                                  className="col-sm-auto mt-3"
                                  src={pictosCategorie(item.categorie)}
                                  alt=""
                                  style={{
                                    height: "",
                                    width: "auto",
                                    paddingRight: "0",
                                    paddingLeft: "0",
                                  }}
                                />
                              </Row>
                              <div
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  programSelect(accessToken, item.id)
                                }
                              >
                                <Row
                                  className="mb-1"
                                  style={{ marginTop: "-12px" }}
                                >
                                  <Row className="stand2">
                                    <Col
                                      className=" p-0  mb-1 px-1"
                                      style={{ width: "" }}
                                    >
                                      {item.categorie.for_room ? (
                                        (item.stand.id === "" ||
                                          item.stand.id === undefined) &&
                                        item.salle === "" ? null : (
                                          <div className="d-flex align-items-center">
                                            <img
                                              title={
                                                item.stand.id === undefined ||
                                                item.stand.id === ""
                                                  ? null
                                                  : "Voir le stand"
                                              }
                                              onClick={() =>
                                                navigationPage(
                                                  item.stand.id === undefined ||
                                                    item.stand.id === ""
                                                    ? ""
                                                    : "/planStand/" +
                                                        item.stand.id
                                                )
                                              }
                                              src={pictos.lieu_icone}
                                              alt=""
                                              style={{
                                                height: "25px",
                                                width: "auto",
                                                cursor:
                                                  item.stand.id === undefined ||
                                                  item.stand.id === ""
                                                    ? ""
                                                    : "pointer",
                                              }}
                                            />
                                            <div className=" pt-1 d-flex align-items-center">{`${upperFirst(
                                              toLower(item.salle)
                                            )}`}</div>
                                          </div>
                                        )
                                      ) : item.stand.code === "" ||
                                        item.stand.code === undefined ? null : (
                                        <div className="d-flex align-items-center">
                                          <img
                                            title="Voir le stand"
                                            onClick={() =>
                                              navigationPage(
                                                item.stand.id === undefined ||
                                                  item.stand.id === ""
                                                  ? ""
                                                  : "/planStand/" +
                                                      item.stand.id
                                              )
                                            }
                                            src={pictos.lieu_icone}
                                            alt=""
                                            style={{
                                              height: "25px",
                                              width: "auto",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <div className=" pt-1 d-flex align-items-center">{`${upperFirst(
                                            toLower(item.stand.code)
                                          )}`}</div>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  <Col
                                    sm="auto"
                                    className="mb-1"
                                    style={{
                                      color: couleurTheme(item.categorie),
                                    }}
                                  >{`${upperFirst(
                                    toLower(item.categorie.label)
                                  )} - ${upperFirst(
                                    toLower(item.theme)
                                  )}`}</Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="programme-titre"
                                    style={{
                                      color: couleurTheme(item.categorie),
                                    }}
                                  >
                                    {upperFirst(toLower(item.titre))}
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                  </div>
                </Row>
              ) : null}

              {partenaireSelected.presentation === "" ? null : (
                <Row className="coordonnee-info">
                  <Row className="coordonnees-titre-infos">
                    <Col className="my-2 p-0">Présentation</Col>
                  </Row>
                  <Row className="" style={{ textAlign: "" }}>
                    <Col
                      className="p-0"
                      style={{ wordBreak: "break-word" }}
                      dangerouslySetInnerHTML={{
                        __html: upperFirst(partenaireSelected.presentation),
                      }}
                    ></Col>
                  </Row>
                </Row>
              )}

              {partenaireSelected.documents.pdf === undefined ||
              partenaireSelected.documents.pdf === "" ||
              partenaireSelected.documents.pdf === null ? null : (
                <Row className="coordonnee-info">
                  <Row className="coordonnees-titre-infos">
                    <Col className="my-2 p-0">Documents</Col>
                  </Row>
                  <Row className="" style={{ textAlign: "justify" }}>
                    <Col className="p-0">
                      {/* <iframe width={'100%'} src={partenaireSelected.documents.pdf + '#/toolbar=1'} title={document}></iframe> */}
                      <NavLink
                        style={{ color: "black" }}
                        target="_blank"
                        to={partenaireSelected.documents.pdf}
                      >
                        {"Fichier PDF"}
                      </NavLink>
                    </Col>
                  </Row>
                </Row>
              )}
              {/* lien_video */}
              {/* {(partenaireSelected.documents.video === undefined || null || '') && (partenaireSelected.documents.lien_video === undefined || null || '') ? */}
              {partenaireSelected.documents.video === undefined ||
              null ||
              "" ? null : (
                <Row className="coordonnee-info">
                  <Row className="coordonnees-titre-infos">
                    <Col className="my-2 p-0">Vidéos</Col>
                    {/* {partenaireSelected.documents.video} */}
                  </Row>
                  <Row className="mb-0" style={{ textAlign: "justify" }}>
                    <Col className="p-0">
                      {/* <iframe allowFullScreen={true} title='La vidéo' src={partenaireSelected.documents.lien_video === undefined || null || '' ? partenaireSelected.documents.video : partenaireSelected.documents.lien_video} frameBorder="0"></iframe> */}
                      <iframe
                        allowFullScreen={true}
                        title="La vidéo"
                        src={partenaireSelected.documents.video}
                        frameBorder="0"
                      ></iframe>
                    </Col>
                  </Row>
                </Row>
              )}

              <Row className="coordonnee-info"></Row>
            </Container>
          </div>
        </Row>
      </Row>
      {equipe.length ? (
        <Row className="footer-profil pt-2" style={{ minHeight: "25vh" }}>
          <Container>
            <Row className="intervention-titre d-flex align-items-center mb-3">
              {" "}
              <p>{"L'Equipe"}</p>{" "}
            </Row>

            {loading2 ? (
              <div className="d-flex justify-content-center p-4">
                <CircularProgress />
              </div>
            ) : (
              equipe.map((item, index) => (
                <Container key={index}>
                  <Row className="intervention-item bg bg-white d-flex align-items-center justify-content-start">
                    <Col>
                      {item.id === monProfil.id ? (
                        <Row style={{}} className="d-flex align-items-center">
                          <Col
                            sm={"auto"}
                            className="photo-profil-participant "
                          >
                            <Avatar
                              id="photoProfil"
                              className=""
                              src={item.photo_path}
                              style={{
                                width: "10vh",
                                height: "10vh",
                                backgroundColor: "",
                              }}
                            />
                          </Col>
                          <Col className="" xs={""}>
                            <Row className="noms-participant">
                              {" "}
                              {upperFirst(toLower(item.prenom)) +
                                " " +
                                upperFirst(toLower(item.nom))}
                            </Row>
                            <Row>{upperFirst(toLower(item.fonction))}</Row>
                          </Col>
                        </Row>
                      ) : (
                        <Row
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            participantSelect(accessToken, item.id);
                          }}
                        >
                          <Col
                            sm={"auto"}
                            className="photo-profil-participant "
                          >
                            <Avatar
                              id="photoProfil"
                              className=""
                              src={item.photo_path}
                              style={{
                                width: "10vh",
                                height: "10vh",
                                backgroundColor: "",
                              }}
                            />
                          </Col>
                          <Col className="" xs={""}>
                            <Row className="noms-participant">
                              {" "}
                              {upperFirst(toLower(item.prenom)) +
                                " " +
                                upperFirst(toLower(item.nom))}
                            </Row>
                            <Row>{upperFirst(toLower(item.fonction))}</Row>
                          </Col>
                        </Row>
                      )}
                    </Col>

                    <Col
                      sm="auto"
                      className="d-flex justify-content-end"
                      style={{ width: "fit-content" }}
                    >
                      {item.is_contact_status === "pending" ||
                      (indexPart.filter((value) => value === item.id)[0] ===
                        item.id &&
                        equipe.filter((val) => val.id === item.id)[0]
                          .is_contact_status === "pending") ? (
                        <img
                          src={pictos.demande_attente_icone}
                          alt=""
                          style={{ width: "30px" }}
                        />
                      ) : item.is_contact_status === "accepted" ? (
                        <img
                          src={pictos.demande_valide_icone}
                          alt=""
                          style={{ width: "30px" }}
                        />
                      ) : item.is_contact_status === "invite" ? (
                        <img
                          title="Ajouter"
                          onClick={() => ouvrirEnvoyer(item.id)}
                          src={pictos.ajout_contact_icone}
                          alt=""
                          style={{ width: "30px", cursor: "pointer" }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Container>
              ))
            )}
          </Container>
        </Row>
      ) : null}
    </Container>
  );
};

export default PartenaireDetail;
