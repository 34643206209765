import { CircularProgress, createTheme } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  evenementSelectRedux,
  maJourneeRedux,
  partenairesRedux,
  participantsRedux,
  programmesRedux,
} from "../redux/evenementsServices";
import { MenuList, ThemeProvider } from "@mui/joy";
import { Container } from "react-bootstrap";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import { ListItemText } from "@mui/material";
import { pictos } from "../assets/img/pictos";
import { useState } from "react";
import ErrorPopup from "./ErrorPopup";
import {
  contactsRedux,
  demandesRedux,
  invitationsRedux,
} from "../redux/profilService";
import textConnect from "../textConnect.json";
import { useEffect } from "react";
import { PoliceDefaut } from "../helpers/contants";

const DashboardMenu = (props) => {
  const monProfil = useSelector((state) => state.profil.profil);
  const userToken = useSelector((state) => state.user.access);
  const evenementSelected = useSelector(
    (state) => state.evenements.evenementSelect
  );
  const dispatch = useDispatch();
  const navigationPage = useNavigate();

  const theme = createTheme({
    typography: {
      fontFamily: PoliceDefaut,
      fontSize: 13,
    },
  });

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  const partenaires = (token, id) => {
    setLoadingItem("partenaires");
    setLoading(true);
    partenairesRedux(token, id)
      .then((reponse) => {
        dispatch({
          type: "evenements/addExposant",
          payload: reponse.data,
        });
        navigationPage("/partenaires");
        window.scrollTo(0, 0);
        setLoading(false);
      })
      .catch((erreur) => {
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };
  const maJournee = (token, id) => {
    setLoadingItem("journee");
    setLoading(true);
    maJourneeRedux(token, id)
      .then((reponse) => {
        setLoading(false);
        dispatch({
          type: "evenements/addMajournee",
          payload: reponse.data,
        });
        console.log("Get ma Journée :", reponse);
        navigationPage("/majournee");
        window.scrollTo(0, 0);
      })
      .catch((erreur) => {
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };
  const participantsList = (token, id) => {
    setLoadingItem("participants");
    setLoading(true);
    participantsRedux(token, id)
      .then((reponse) => {
        setLoading(false);
        dispatch({
          type: "evenements/addParticipants",
          payload: reponse.data,
        });
        navigationPage("/participants");
        window.scrollTo(0, 0);
        contactsRedux(userToken).then((reponse) => {
          // console.log(reponse.data)
          setLoading(false);
          dispatch({
            type: "Profil/addContacts",
            payload: reponse.data,
          });

          demandesRedux(userToken).then((reponse) => {
            // console.log(reponse.data)

            dispatch({
              type: "Profil/addDemandes",
              payload: reponse.data,
            });
          });

          invitationsRedux(userToken).then((reponse) => {
            // console.log(reponse.data)

            dispatch({
              type: "Profil/addInvitations",
              payload: reponse.data,
            });
          });

          // navigationPage('/contacts')
        });
      })
      .catch((erreur) => {
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };

  const mesProgrammes = (token, idEvent) => {
    setLoadingItem("programmes");
    setLoading(true);
    programmesRedux(token, idEvent)
      .then(async (response) => {
        dispatch({
          type: "evenements/addProgramme",
          payload: response.data,
        });
        navigationPage("/programmes");
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((erreur) => {
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };

  const [loading, setLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState("");

  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const closeErrorHandle = () => {
    setOpenError(false);
  };

  return (
    <>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />
      <ThemeProvider theme={theme}>
        <Container fluid className="d-flex justify-content-end">
          <MenuList
            className="menu-dashboard1"
            aria-labelledby="btn-dashboard"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.96)",
              borderRadius: "20px 0 0 20px",
              zIndex: "1000",
              right: "0",
              top: "10%",
              position: "fixed",
              boxShadow: "5px 10px 15px #E8E8E8",
              display: props.open === "true" ? "flex" : "none",
            }}
            variant="solid"
            style={{}}
          >
            <MenuItem
              selected={props.selected === 1 ? true : false}
              className=""
              style={{ height: "fit-content" }}
              onClick={() => mesProgrammes(userToken, evenementSelected.id)}
            >
              <ListItemText className="texte" style={{ width: "20px" }}>
                {" "}
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#17418d", fontFamily: PoliceDefaut }}
                >
                  {loading ? (
                    loadingItem === "programmes" ? (
                      <CircularProgress
                        style={{
                          height: "auto",
                          width: "16%",
                          color: "#17418d",
                        }}
                      />
                    ) : (
                      "Programmes"
                    )
                  ) : (
                    "Programmes"
                  )}
                </div>
              </ListItemText>
              <img
                className=""
                src={pictos.programme_icone}
                alt=""
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#f8ad41",
                  width: "16%",
                }}
              />
            </MenuItem>
            <MenuItem
              selected={props.selected === 2 ? true : false}
              className=""
              style={{ height: "fit-content", fontFamily: PoliceDefaut }}
              onClick={() => partenaires(userToken, evenementSelected.id)}
            >
              <ListItemText className="texte" style={{ width: "20px" }}>
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#17418d", fontFamily: PoliceDefaut }}
                >
                  {loading ? (
                    loadingItem === "partenaires" ? (
                      <CircularProgress
                        style={{
                          height: "auto",
                          width: "16%",
                          color: "#17418d",
                        }}
                      />
                    ) : (
                      "Partenaires exposants"
                    )
                  ) : (
                    "Partenaires exposants"
                  )}
                </div>
              </ListItemText>
              <img
                className=""
                src={pictos.partenaires_icone}
                alt=""
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#1bace4",
                  width: "16%",
                }}
              />
            </MenuItem>
            <MenuItem
              selected={props.selected === 3 ? true : false}
              className=""
              style={{ height: "fit-content", fontFamily: PoliceDefaut }}
              onClick={() => maJournee(userToken, evenementSelected.id)}
            >
              <ListItemText className="texte" style={{ width: "20px" }}>
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#17418d", fontFamily: PoliceDefaut }}
                >
                  {loading ? (
                    loadingItem === "journee" ? (
                      <CircularProgress
                        style={{
                          height: "auto",
                          width: "16%",
                          color: "#17418d",
                        }}
                      />
                    ) : (
                      "Ma journée"
                    )
                  ) : (
                    "Ma journée"
                  )}
                </div>
              </ListItemText>
              <img
                className=""
                src={pictos.journee_icone}
                alt=""
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#e84d14",
                  width: "16%",
                }}
              />
            </MenuItem>
            <MenuItem
              selected={props.selected === 4 ? true : false}
              className=""
              style={{ height: "fit-content", fontFamily: PoliceDefaut }}
              onClick={() => participantsList(userToken, evenementSelected.id)}
            >
              <ListItemText className="texte" style={{ width: "20px" }}>
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#17418d", fontFamily: PoliceDefaut }}
                >
                  {loading ? (
                    loadingItem === "participants" ? (
                      <CircularProgress
                        style={{
                          height: "auto",
                          width: "16%",
                          color: "#17418d",
                        }}
                      />
                    ) : (
                      "Participants"
                    )
                  ) : (
                    "Participants"
                  )}
                </div>
              </ListItemText>
              <img
                className=""
                src={pictos.participant_icone}
                alt=""
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#0078bc",
                  width: "16%",
                }}
              />
            </MenuItem>
            <MenuItem
              selected={props.selected === 5 ? true : false}
              className=""
              style={{ height: "fit-content", fontFamily: PoliceDefaut }}
              onClick={() => navigationPage("/planStand/0")}
            >
              <ListItemText className="texte" style={{ width: "20px" }}>
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#17418d", fontFamily: PoliceDefaut }}
                >
                  {"Plan des stands"}
                </div>
              </ListItemText>
              <img
                className=""
                src={pictos.plan_stand_icone}
                alt=""
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#c01630",
                  width: "16%",
                }}
              />
            </MenuItem>
          </MenuList>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default DashboardMenu;
