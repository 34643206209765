import React from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../components/Navigation";
import "../styles/nousContacter.css";
import Breadcrumb from "../components/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { pagesContent, refreshToken } from "../redux/profilService";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import ErrorPopup from "../components/ErrorPopup";
import Chemin from "../components/Chemin";
import textConnect from "../textConnect.json";

const Copyright = () => {
  // const copyrigtInfos = useSelector(state => state.profil.configurations)
  const copyrigtInfos = useSelector((state) =>
    state.profil.contenusTexte !== undefined ? state.profil.contenusTexte : ""
  );
  const monProfil = useSelector((state) => state.profil.profil);
  const location = useLocation();
  const param = useParams();

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const closeErrorHandle = () => {
    setOpenError(false);
  };

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter((item) => item.nom === "Accueil");
  breadcrumbs_temp.push({
    url: location.pathname,
    nom: "Conditions d'utilisation",
  });
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
    pagesContent()
      .then((reponse) => {
        dispatch({
          type: "Profil/addTextContent",
          payload: reponse.data,
        });
        setLoader(false);
      })
      .catch((erreur) => {
        setLoader(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          // if (erreur.response.status === 401) {
          //     refreshToken(refresh_token).then(
          //         async (reponse) => {
          //             dispatch(
          //                 {
          //                     type: 'user/addRefresh',
          //                     payload: reponse.data.refresh
          //                 }
          //             );
          //             dispatch(
          //                 {
          //                     type: 'user/addAccess',
          //                     payload: reponse.data.access
          //                 }
          //             )
          //             console.log('token refresh...')
          //             selectEvent(token, id)
          //         })
          // }
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
        }
      });
  }, []);

  return (
    <Container fluid>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />

      <Row>
        <Navigation
          button={false}
          titre={"Conditions d'utilisation"}
          photo={monProfil.photo_path}
          retour="dashboard"
          menus={param.login === "0" ? false : true}
        />
      </Row>

      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "30vh" }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Row className="contact-container" style={{}}>
          <p
            dangerouslySetInnerHTML={{
              __html: copyrigtInfos.copyrights,
            }}
            style={{}}
          ></p>
        </Row>
      )}
    </Container>
  );
};

export default Copyright;
