import React from "react";
import { Breadcrumbs } from "@material-ui/core";
import { useContext } from "react";
import { BreadcrumbContext } from "../Context";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toLower, upperFirst } from "lodash";
import { PoliceDefaut } from "../helpers/contants";

const Chemin = (props) => {
  //Breadcrumbs
  // const breadCrumbs = useSelector(state => state.profil.chemin)
  const filterBread = props.breadCrumbs.filter((val) => val.nom !== "");
  return (
    <Breadcrumbs
      separator="/"
      aria-label="breadcrumb"
      className="py-2 mb-2"
      style={{
        border: "",
        fontFamily: PoliceDefaut,
        textDecoration: "none",
        color: "#17418d",
      }}
    >
      {filterBread.map((item, index) => {
        return index >= 2 ? (
          <Link
            key={index}
            className=""
            to={item.url}
            style={{
              textDecoration: "none",
              color: index !== filterBread.length - 1 ? "#17418d" : "#0e71b4",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              width: "120px",
              textTransform: "capitalize",
            }}
          >
            {upperFirst(toLower(item.nom))}
          </Link>
        ) : (
          <Link
            style={{
              textDecoration: "none",
              color: index !== filterBread.length - 1 ? "#17418d" : "#0e71b4",
              // textTransform: "capitalize",
            }}
            key={index}
            to={item.url}
          >
            {upperFirst(toLower(item.nom))}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Chemin;
