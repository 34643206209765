import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Navigation from "../components/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { pictos } from "../assets/img/pictos";
import "../styles/programme.css";
import { Avatar, Badge } from "@mui/joy";
import { upperFirst } from "lodash";
import { couleurTheme } from "./fonctions";
import {
  NotificationsRedux,
  refreshToken,
  validerRdv,
} from "../redux/profilService";
import { UpdateNotification } from "../redux/updatesServices";
import { useContext } from "react";
import { NotifNbreContext } from "../Context";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import ErrorPopup from "../components/ErrorPopup";
import Breadcrumb from "../components/Breadcrumb";
import { useEffect } from "react";
import { addChemin, addHistorique } from "../redux/profilSlice";
import Chemin from "../components/Chemin";
import textConnect from "../textConnect.json";

const Rendezvous = () => {
  const monProfil = useSelector((state) => state.profil.profil);
  const accessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector((state) => state.user.refresh);
  // const rdv = useSelector(state => state.profil.notifications.filter(item => item.data_target !== null))
  const rdv = useSelector((state) => state.profil.rdv);
  const listeNotifications = useSelector((state) => state.profil.notifications);

  const nbreNotif = useContext(NotifNbreContext);
  const dispatch = useDispatch();
  const navigationPage = useNavigate();

  const [circle1, setCircle1] = useState(false);
  const [circle2, setCircle2] = useState(false);

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  const RdvHandle = (rdv_id, choix) => {
    const notifRdv = listeNotifications.filter(
      (item) => item.id_target === rdv_id
    );
    if (choix === "accepter") {
      setCircle1(true);
    }
    if (choix === "refuser") {
      setCircle2(true);
    }

    try {
      validerRdv(rdv.data_target.id_programme, monProfil.id, accessToken, choix)
        .then(async (response) => {
          setCircle1(false);
          setCircle2(false);

          navigationPage("/");

          if (notifRdv.length !== 0) {
            UpdateNotification(notifRdv[0].id, accessToken).then((response) => {
              NotificationsRedux(accessToken).then((reponse) => {
                dispatch({
                  type: "Profil/addNotifications",
                  payload: reponse.data.filter(
                    (item) =>
                      item.participant_recepteur.id === monProfil.id &&
                      item.etat === false
                  ),
                });
                nbreNotif.setNbre(
                  reponse.data.filter(
                    (item) =>
                      item.participant_recepteur.id === monProfil.id &&
                      item.etat === false
                  ).length
                );
              });
            });
          }
        })
        .catch((error) => {
          console.log("erreur rdv :");
          // console.error(error);
          if (!netStatus) {
            setMsgError(textConnect.internet);
            setOpenError(true);
          }
          if (error.response) {
            if (error.response.status !== 401) {
              setMsgError(textConnect.error);
              setOpenError(true);
            }
          } else {
            setMsgError(textConnect.internet);
            setOpenError(true);
          }
          setCircle1(false);
          setCircle2(false);
        });
    } catch (erreur) {
      // console.log('erreur sur le rendez-vous')
      setCircle1(false);
      setCircle2(false);
      if (!netStatus) {
        setMsgError(textConnect.internet);
        setOpenError(true);
      }
      if (erreur.response) {
        if (erreur.response.status !== 401) {
          setMsgError(textConnect.error);
          setOpenError(true);
        }
        if (erreur.response.status === 401) {
          refreshToken(refresh_token).then(async (reponse) => {
            dispatch({
              type: "user/addRefresh",
              payload: reponse.data.refresh,
            });

            dispatch({
              type: "user/addAccess",
              payload: reponse.data.access,
            });

            console.log("token refresh...");
            RdvHandle(rdv_id, choix);
          });
        }
      } else {
        setMsgError(textConnect.internet);
        setOpenError(true);
      }
    }
  };
  const location = useLocation();
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");

  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter(
    (item) => item.nom === "Accueil" || item.nom === "Notifications"
  );
  breadcrumbs_temp.push({ url: location.pathname, nom: "Rendez-vous" });

  const closeErrorHandle = () => {
    setOpenError(false);
  };

  useEffect(() => {
    dispatch(addHistorique("/"));
    dispatch(addChemin(breadcrumbs_temp));
  }, []);

  return (
    <Container fluid>
      <Row>
        <Navigation
          titre={"Demande de rendez-vous"}
          photo={monProfil.photo_path}
          menus={true}
        />
      </Row>

      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />
      <Row>
        <Row className="rdv-container" style={{ color: "#17418d" }}>
          <Col>
            <Row className="d-flex align-items-center mb-2 mx-0">
              <Col
                sm={"auto"}
                className="programme-date mt-2"
                style={{
                  width: "fit-content",
                  backgroundColor: couleurTheme({ couleur: "" }),
                  color: "white",
                }}
              >
                {" "}
                {rdv.data_target.heure_fin === "" ||
                rdv.data_target.heure_fin === undefined ||
                rdv.data_target.heure_fin === null
                  ? rdv.data_target.heure_debut
                  : rdv.data_target.heure_debut +
                    " - " +
                    rdv.data_target.heure_fin}{" "}
              </Col>
              {/* <Col xs={''} className='text-uppercase' style={{ color: couleurTheme(programmeSelected.categorie.couleur) }}>{programmeSelected.theme}</Col> */}
              <Col className="mt-2" style={{ padding: "0" }}>
                <div className="d-flex align-items-end ">
                  <img
                    title=""
                    src={pictos.lieu_icone}
                    alt=""
                    style={{ height: "25px", width: "auto" }}
                  />
                  <div>{rdv.data_target.stand.code}</div>
                </div>
              </Col>
              <Col
                sm="auto"
                className=" d-flex justify-content-end"
                style={{ width: "fit-content" }}
              >
                <img
                  className="col-sm-auto mt-1"
                  src={""}
                  alt=""
                  style={{ height: "50px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                sm="auto"
                className="mb-2"
                style={{}}
              >{`${"Rendez-vous"}`}</Col>
            </Row>
            <Row>
              <Col className="programme-titre" style={{}}>
                {rdv.data_target.titre}
              </Col>
            </Row>
            <Row className="mb-2" style={{ textAlign: "justify" }}>
              <Col className="pb-2" style={{}}>
                {rdv.data_target.description === "" ||
                rdv.data_target.description === undefined ||
                rdv.data_target.description === "None"
                  ? null
                  : rdv.data_target.description}
              </Col>
              {/* <Col sm={''} className=' border programme-description'><p className=''>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto reprehenderit dolores expedita, enim doloremque corporis iste eveniet unde! Labore, unde?</p></Col> */}
            </Row>
            <Row className="mb-2" style={{ textAlign: "justify" }}>
              <Col className="pb-2" style={{}}>
                Lieu : {rdv.data_target.lieu}
              </Col>
              {/* <Col sm={''} className=' border programme-description'><p className=''>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto reprehenderit dolores expedita, enim doloremque corporis iste eveniet unde! Labore, unde?</p></Col> */}
            </Row>
            <Row
              className=""
              style={{ borderTop: "2px solid #00a7d5", marginLeft: "2px" }}
            >
              <Container className="intervenants-container">
                <Row
                  className="font-weight-bold h6 my-2"
                  style={{ color: "#00a7d5" }}
                >
                  Demande de rendez-vous avec :
                </Row>

                <Row className=" intervenant-item d-flex align-items-center">
                  <Col sm="auto" className="photo-profil-participant ">
                    <Avatar
                      id="photoProfil"
                      className=""
                      src={rdv.participant_emeteur.photo}
                      style={{
                        width: "9vh",
                        height: "9vh",
                        backgroundColor: "",
                      }}
                    />
                  </Col>
                  <Col className="">
                    <Row className="noms-participant">
                      {" "}
                      {upperFirst(rdv.participant_emeteur.prenom) +
                        " " +
                        upperFirst(rdv.participant_emeteur.nom)}
                    </Row>
                    <Row>{upperFirst(rdv.participant_emeteur.fonction)}</Row>
                    <Row>
                      {/* {item.raison_sociale === '' || item.raison_sociale === null ? !item.from_structure ? upperFirst(item.organisation.nom) : item.organisation.adresse_organisation.ville : upperFirst(item.raison_sociale)} */}
                      {rdv.participant_emeteur.raison_sociale === undefined ||
                      rdv.participant_emeteur.raison_sociale === "" ||
                      rdv.participant_emeteur.raison_sociale === null
                        ? !rdv.participant_emeteur.from_structure
                          ? upperFirst(rdv.participant_emeteur.organisation.nom)
                          : upperFirst(
                              rdv.participant_emeteur.organisation
                                .adresse_organisation.ville
                            )
                        : upperFirst(rdv.participant_emeteur.raison_sociale)}
                    </Row>
                    {/* <Row>{upperFirst(rdv[0].participant_emeteur.organisation.nom)}</Row> */}
                  </Col>
                </Row>

                {/* {programmeSelected.intervenants.map((item, index) =>
                                        <Row key={index} className=' intervenant-item d-flex align-items-center border-bottom'>
                                            <Col sm='auto' className='photo-profil-participant '>

                                                <Badge className='d-flex align-items-center justify-content-left' style={{ width: 'fit-content' }}
                                                    overlap='circular'
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={[
                                                        categories(item.categories).map((item, index) =>
                                                            <Avatar key={index} className='col' src={item.icone} alt="" style={{ width: '100%', height: '100%', marginRight: '0', marginLeft: '0', backgroundColor: '  white' }} />
                                                        )]
                                                    }
                                                >

                                                    <Avatar id='photoProfil' className='' src={item.photo_path} style={{ width: '9vh', height: '9vh', backgroundColor: '' }} />


                                                </Badge>
                                            </Col>
                                            <Col className=''>
                                                <Row className='noms-participant'> {upperFirst(item.user.prenom) + ' ' + upperFirst(item.user.nom)}</Row>
                                                <Row>{upperFirst(item.fonction)}</Row>
                                                <Row>{upperFirst(item.organisation.nom)}</Row>
                                            </Col>
                                            <Col sm='auto' className='d-flex justify-content-end' style={{ width: 'fit-content' }}>
                                                {mesContacts.filter((item2) => { return item2.id === item.id }).length ? <Check /> : mesDemandes.filter((item3) => { return item3.a_participant.id === item.id }).length ? <HourglassFullTwoTone /> : mesInvitations.filter((item4) => { return item4.de_participant.id === item.id }).length ? <HourglassEmptyTwoTone /> : item.id === monProfil.id ? null : <img onClick={() => handleEnvoiDemande(item.id)} src={pictos.ajout_contact_icone} alt="" style={{ width: '30px', cursor: 'pointer' }} />}
                                            </Col>
                                        </Row>
                                    )} */}

                <Row
                  xs={2}
                  className="coordonnee-info py-3 d-flex justify-content-end align-items-center"
                >
                  <Col
                    className="px-2"
                    sm="auto"
                    style={{ width: "fit-content" }}
                  >
                    {circle1 ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        disabled={circle2}
                        style={{ borderRadius: "50px" }}
                        onClick={() => RdvHandle(rdv.id_target, "accepter")}
                      >
                        Accepter
                      </Button>
                    )}
                  </Col>
                  <Col
                    className="p-0"
                    sm="auto"
                    style={{ width: "fit-content" }}
                  >
                    {circle2 ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        disabled={circle1}
                        variant="secondary"
                        style={{ borderRadius: "50px" }}
                        onClick={() => RdvHandle(rdv.id_target, "refuser")}
                      >
                        Refuser
                      </Button>
                    )}
                  </Col>
                </Row>
              </Container>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Rendezvous;
