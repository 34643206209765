import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { pictos } from "../assets/img/pictos";
import { Input } from "@mui/joy";
import { SearchOutlined, Warning } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { couleurTheme, pictosCategorie, toNormalForm } from "./fonctions";
import "../styles/programme.css";
import {
  participantsRedux,
  programmeSelectRedux,
} from "../redux/evenementsServices";
import {
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import _, { lowerCase, toLower, upperFirst } from "lodash";
import Navigation from "../components/Navigation";
import { refreshToken } from "../redux/profilService";
import { isEmptyArray } from "formik";
import ErrorPopup from "../components/ErrorPopup";
import Breadcrumb from "../components/Breadcrumb";
import SearchInput from "../components/SearchInput";
import Chemin from "../components/Chemin";
import textConnect from "../textConnect.json";
import { PoliceDefaut, PoliceDefautBold } from "../helpers/contants";

const Journee = () => {
  const laJourneeInit = _.sortBy(
    useSelector((state) => state.evenements.maJournee),
    [
      function (val) {
        return val.heure_deb;
      },
    ]
  );
  const evenementSelected = useSelector(
    (state) => state.evenements.evenementSelect
  );

  const participantSelected = useSelector(
    (state) => state.evenements.participantSelect
  );

  const monProfil = useSelector((state) => state.profil.profil);

  const [laJournee, setLaJournee] = useState(
    _.sortBy(laJourneeInit, [
      function (val) {
        return val.heure_deb;
      },
    ])
  );

  const idEvent = useSelector((state) => state.evenements.evenementSelect.id);
  const AccessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector((state) => state.user.refresh);
  const dispatch = useDispatch();
  const navigationPage = useNavigate();

  const [filtreMot, setFiltreMot] = useState("");

  const [loading, setLoading] = useState(false);

  //Fonction filtre barre de recherche
  const filtreProgrammes = (e) => {
    setFiltreMot(e.target.value);
  };

  function annulerFiltre() {
    setLaJournee(
      _.sortBy(laJourneeInit, [
        function (val) {
          return val.heure_deb;
        },
      ])
    );
    setAnchorEl(null);
    setCatCheck([]);
  }

  // function FiltrerCategorie(categorie, tableau) {
  //     let resultat
  //     let filtre1 = tableau.filter(item =>
  //         item.categorie === null
  //     )
  //     let filtre2 = tableau.filter(item =>
  //         item.categorie !== null
  //     )

  //     if (categorie !== null) {
  //         resultat = filtre2.filter(item =>

  //             item.categorie.label === categorie
  //         )
  //         setLaJournee(_.sortBy(resultat, [function (val) { return val.heure_deb }]))
  //         setAnchorEl(null)
  //     }
  //     else {
  //         setLaJournee(_.sortBy(filtre1, [function (val) { return val.heure_deb }]))
  //         setAnchorEl(null)
  //     }

  //     console.log('le resultat : ', resultat)
  //     console.log('le resultat2 : ', filtre1)
  //     console.log('le resultat3 : ', filtre2)
  //     // setLaJournee(resultat)
  //     // setAnchorEl(null)
  // }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //     setLaJournee(_.sortBy(laJourneeInit, [function (val) { return val.heure_deb }]))
  //     console.log(laJourneeInit)
  // }, [laJourneeInit])
  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  const laJourneeUnique = [
    ...new Map(
      laJourneeInit.map((item) => [item.categorie.label, item])
    ).values(),
  ].sort(function (a, b) {
    if (upperFirst(a.categorie.label) < upperFirst(b.categorie.label)) {
      return -1;
    }
    if (upperFirst(a.categorie.label) > upperFirst(b.categorie.label)) {
      return 1;
    }
    return 0;
  });
  const [catCheck, setCatCheck] = useState([]);

  function FiltrerCategorie(categorie = [], tableau = []) {
    let resultat = [];
    if (categorie.length > 1) {
      // console.log('superieur a 1..................')
      // console.log(categorie)
      let tabTemp = [];

      for (let index = 0; index < categorie.length; index++) {
        // console.log('itemeeee', categorie[index])
        tableau
          .filter(
            (item) =>
              lowerCase(item.categorie.label) === lowerCase(categorie[index])
          )
          .map((item2) => tabTemp.push(item2));

        resultat = tabTemp;
        console.log(resultat);
      }
    } else {
      if (!isEmptyArray(categorie)) {
        resultat = tableau.filter(
          (item) => lowerCase(item.categorie.label) === lowerCase(categorie)
        );
      } else {
        resultat = tableau.filter((item) =>
          item.categorie.label.includes(categorie)
        );
      }
      // console.log('igiugihu:', tableau.filter(item =>
      //     lowerCase(item.categorie.label) === (lowerCase(categorie))
      // ))
    }

    // console.log('le resultat : ', resultat)
    setLaJournee(resultat);
    // setAnchorEl(null)
  }

  const handleCheck = (e, value) => {
    if (e.target.checked) {
      setCatCheck((old) => [...old, value]);
    } else {
      setCatCheck(catCheck.filter((item) => item !== value));
      console.log(catCheck);
    }
  };
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
    // console.log('dans le hook.......................')
    // console.log(catCheck)
    FiltrerCategorie(catCheck, laJourneeInit);
  }, [catCheck]);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const closeErrorHandle = () => {
    setOpenError(false);
  };

  const programSelect = (token, id) => {
    setLoading(true);
    programmeSelectRedux(token, id)
      .then((reponse) => {
        setLoading(false);
        dispatch({
          type: "evenements/addProgrammeSelect",
          payload: reponse.data,
        });
        navigationPage("/programme/" + id + "/1");
        window.scrollTo(0, 0);
      })
      .catch((erreur) => {
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }

          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");
              programSelect(token, id);
            });
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };
  const location = useLocation();
  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter((item) => item.nom === "Accueil");
  breadcrumbs_temp.push({ url: location.pathname, nom: "Ma journée" });

  return (
    <Container fluid>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />
      <Menu
        className="menu-filtre"
        id="filtre-menu"
        aria-labelledby="btn-filtre"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography
          className="mx-3 border-bottom"
          style={{ fontFamily: PoliceDefautBold }}
        >
          {" Filtrer par catégorie :"}
        </Typography>

        {laJourneeUnique.map((item, index) => (
          <MenuItem
            key={index}
            className=""
            style={{ fontFamily: PoliceDefaut }}
          >
            <Col>
              <label htmlFor={item.id}>
                {upperFirst(
                  toLower(
                    item.categorie.label === ""
                      ? "mes rendez-vous"
                      : item.categorie.label
                  )
                )}
              </label>
            </Col>
            <Col className="" sm="auto">
              <input
                id={item.id}
                checked={
                  catCheck.filter((cat) => cat === item.categorie.label).length
                    ? true
                    : false
                }
                type="checkbox"
                name=""
                onChange={(e) => handleCheck(e, item.categorie.label)}
              />
            </Col>
          </MenuItem>
        ))}
        <MenuItem
          style={{ fontFamily: PoliceDefaut }}
          className="border-top"
          onClick={() => annulerFiltre()}
        >
          Annuler le filtre
        </MenuItem>
      </Menu>
      <Row>
        <Navigation
          button={true}
          titre={"Ma journée"}
          icone={pictos.journee_rond_icone}
          evenement={evenementSelected.photo}
          photo={monProfil.photo_path}
          retour="dashboard"
          selected={3}
          open={(!open).toString()}
          menus={true}
        />
        {/* <NavbarParticipants
                    titre={'Ma journée'}
                    icone={pictos.journee_rond_icone}
                    button={true}
                    evenement={evenementSelected.photo}
                    photo={monProfil.photo_path}
                /> */}
      </Row>

      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      <Row className="programme-container d-flex justify-content-center">
        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            {laJournee.length ? (
              <Row className=" mb-4 d-flex align-items-center justify-content-center">
                <SearchInput
                  placeholder={"Rechercher"}
                  filtre={filtreProgrammes}
                />

                <Col
                  sm="auto"
                  xl={""}
                  className=""
                  style={{ width: "fit-content" }}
                >
                  <NavLink
                    style={{ textDecoration: "none", color: "#17418d" }}
                    aria-controls={open ? "filtre-menu" : undefined}
                    aria-haspopup={true}
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Row
                      className="d-flex justify-content-center"
                      style={{
                        textAlign: "center",
                        height: "20px",
                        margin: "",
                      }}
                    >
                      {" "}
                      {"Filtrer"}{" "}
                    </Row>
                    <img
                      className="row"
                      src={pictos.filtrer_icone}
                      alt=""
                      style={{ width: "30px", margin: "auto" }}
                    />
                  </NavLink>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {/* <Row className='mb-2'>
                            <Col className=' d-flex justify-content-end'><Button onClick={() => setCreateRdv(true)}>Créer un rendez-vous <Add /></Button></Col>

                        </Row> */}
            <Row
              xs={1}
              className="d-flex align-items-center justify-content-center"
            >
              {laJournee.length ? (
                laJournee.filter((item) => {
                  return filtreMot.toLowerCase() === ""
                    ? item
                    : item.theme === undefined ||
                      item.theme === "" ||
                      item.theme === null
                    ? toNormalForm(item.titre.toLowerCase()).includes(
                        toNormalForm(filtreMot.toLowerCase())
                      ) ||
                      toNormalForm(toLower(item.description)).includes(
                        toNormalForm(toLower(filtreMot))
                      )
                    : toNormalForm(item.titre.toLowerCase()).includes(
                        toNormalForm(filtreMot.toLowerCase())
                      ) ||
                      toNormalForm(item.description.toLowerCase()).includes(
                        toNormalForm(filtreMot.toLowerCase())
                      ) ||
                      toNormalForm(item.theme.toLowerCase()).includes(
                        toNormalForm(filtreMot.toLowerCase())
                      );
                }).length === 0 ? (
                  <p
                    className=""
                    style={{
                      textAlign: "center",
                      color: "#17418d",
                      fontSize: "120%",
                    }}
                  >
                    Aucun résultat trouvé
                  </p>
                ) : (
                  laJournee
                    .filter((item) => {
                      return filtreMot.toLowerCase() === ""
                        ? item
                        : item.theme === undefined ||
                          item.theme === "" ||
                          item.theme === null
                        ? toNormalForm(item.titre.toLowerCase()).includes(
                            toNormalForm(filtreMot.toLowerCase())
                          ) ||
                          toNormalForm(toLower(item.description)).includes(
                            toNormalForm(toLower(filtreMot))
                          )
                        : toNormalForm(item.titre.toLowerCase()).includes(
                            toNormalForm(filtreMot.toLowerCase())
                          ) ||
                          toNormalForm(item.description.toLowerCase()).includes(
                            toNormalForm(filtreMot.toLowerCase())
                          ) ||
                          toNormalForm(item.theme.toLowerCase()).includes(
                            toNormalForm(filtreMot.toLowerCase())
                          );
                    })
                    .map((item, index) => (
                      <Row key={index} className="programme-item">
                        <Col>
                          <Row className="d-flex align-items-center">
                            <Col
                              sm="auto"
                              className="programme-date"
                              style={{
                                backgroundColor: couleurTheme(item.categorie),
                                marginLeft: "10px",
                              }}
                            >
                              {item.heure_fin === "" ||
                              item.heure_fin === undefined ||
                              item.heure_fin === null
                                ? item.heure_deb
                                : item.heure_deb + " - " + item.heure_fin}
                            </Col>
                            <Col
                              className="stand1 p-0"
                              style={{ padding: "", width: "" }}
                            >
                              {item.categorie.for_room ? (
                                (item.stand.id === "" ||
                                  item.stand.id === undefined) &&
                                item.salle === "" ? null : (
                                  <div className="d-flex align-items-center">
                                    <img
                                      title={
                                        item.stand.id === undefined ||
                                        item.stand.id === ""
                                          ? ""
                                          : "Voir le stand"
                                      }
                                      onClick={() =>
                                        navigationPage(
                                          item.stand.id === undefined ||
                                            item.stand.id === ""
                                            ? ""
                                            : "/planStand/" + item.stand.id
                                        )
                                      }
                                      src={pictos.lieu_icone}
                                      alt=""
                                      style={{
                                        height: "25px",
                                        width: "auto",
                                        cursor:
                                          item.stand.id === undefined ||
                                          item.stand.id === ""
                                            ? ""
                                            : "pointer",
                                      }}
                                    />
                                    <div>{upperFirst(toLower(item.salle))}</div>
                                  </div>
                                )
                              ) : item.stand.code === "" ||
                                item.stand.code === undefined ? null : (
                                <div className="d-flex align-items-center">
                                  <img
                                    title="Voir le stand"
                                    onClick={() =>
                                      navigationPage(
                                        item.stand.id === undefined ||
                                          item.stand.id === ""
                                          ? ""
                                          : "/planStand/" + item.stand.id
                                      )
                                    }
                                    src={pictos.lieu_icone}
                                    alt=""
                                    style={{
                                      height: "25px",
                                      width: "auto",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <div>
                                    {upperFirst(toLower(item.stand.code))}
                                  </div>
                                </div>
                              )}
                            </Col>
                            <img
                              className="col-sm-auto px-2 mt-3"
                              src={pictosCategorie(item.categorie)}
                              alt=""
                              style={{
                                height: "",
                                width: "auto",
                                paddingRight: "0",
                                paddingLeft: "0",
                              }}
                            />
                          </Row>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => programSelect(AccessToken, item.id)}
                          >
                            <Row
                              className="mb-1"
                              style={{ marginTop: "-12px" }}
                            >
                              <Row className="stand2">
                                <Col
                                  className=" p-0  mb-1 px-1"
                                  style={{ width: "" }}
                                >
                                  {item.categorie.for_room ? (
                                    (item.stand.id === "" ||
                                      item.stand.id === undefined) &&
                                    item.salle === "" ? null : (
                                      <div className="d-flex align-items-center">
                                        <img
                                          title={
                                            item.stand.id === undefined ||
                                            item.stand.id === ""
                                              ? ""
                                              : "Voir le stand"
                                          }
                                          onClick={() =>
                                            navigationPage(
                                              item.stand.id === undefined ||
                                                item.stand.id === ""
                                                ? ""
                                                : "/planStand/" + item.stand.id
                                            )
                                          }
                                          src={pictos.lieu_icone}
                                          alt=""
                                          style={{
                                            height: "25px",
                                            width: "auto",
                                            cursor:
                                              item.stand.id === undefined ||
                                              item.stand.id === ""
                                                ? ""
                                                : "pointer",
                                          }}
                                        />
                                        <div>
                                          {upperFirst(toLower(item.salle))}
                                        </div>
                                      </div>
                                    )
                                  ) : item.stand.code === "" ||
                                    item.stand.code === undefined ? null : (
                                    <div className="d-flex align-items-center">
                                      <img
                                        title="Voir le stand"
                                        onClick={() =>
                                          navigationPage(
                                            item.stand.id === undefined ||
                                              item.stand.id === ""
                                              ? ""
                                              : "/planStand/" + item.stand.id
                                          )
                                        }
                                        src={pictos.lieu_icone}
                                        alt=""
                                        style={{
                                          height: "25px",
                                          width: "auto",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <div>
                                        {upperFirst(toLower(item.stand.code))}
                                      </div>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Col
                                sm="auto"
                                className=""
                                style={{
                                  color: couleurTheme(item.categorie),
                                  marginTop: "",
                                }}
                              >
                                {item.theme === undefined ||
                                item.theme === null ||
                                item.theme === ""
                                  ? `${upperFirst(
                                      toLower(item.categorie.label)
                                    )}`
                                  : item.categorie.label === "" ||
                                    item.categorie.label === null
                                  ? upperFirst(toLower(item.theme))
                                  : `${upperFirst(
                                      toLower(item.categorie.label)
                                    )} - ${upperFirst(toLower(item.theme))}`}
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                className="programme-titre"
                                style={{ color: couleurTheme(item.categorie) }}
                              >
                                {upperFirst(toLower(item.titre))}
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                className="programme-description"
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: upperFirst(item.description),
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ))
                )
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    color: "#17418d",
                    fontSize: "150%",
                  }}
                >
                  {" Vous n'avez aucun programme"}
                </div>
              )}
            </Row>
          </>
        )}
      </Row>
    </Container>
  );
};

export default Journee;
