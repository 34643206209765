import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { pictos } from "../assets/img/pictos";
import { upperFirst } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  NotificationsRedux,
  accepterDemandeRedux,
  annulerDemandeRedux,
  chatSelect,
  contactsRedux,
  demandesRedux,
  invitationsRedux,
  refreshToken,
  refuserDemandeRedux,
} from "../redux/profilService";

import "../styles/contacts.css";
import { NotifNbreContext } from "../Context";
import { Box, Chip, Tab, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy";
import Navigation from "../components/Navigation";
import SearchInput from "../components/SearchInput";
import { chatSelected, toNormalForm } from "./fonctions";
import { UpdateNotification } from "../redux/updatesServices";
import { participantSelectRedux } from "../redux/evenementsServices";
import Breadcrumb from "../components/Breadcrumb";
import Chemin from "../components/Chemin";
import { addHistorique } from "../redux/profilSlice";
import ErrorPopup from "../components/ErrorPopup";
import textConnect from "../textConnect.json";
import { PoliceDefaut } from "../helpers/contants";

const Contacts = () => {
  // const listeContacts = useSelector(state => state.profil.contacts)
  // const listeDemandes = useSelector(state => state.profil.demandes)
  // const listeInvitations = useSelector(state => state.profil.invitations)
  const [listeContacts, setListeContacts] = useState([]);
  const [listeDemandes, setListeDemandes] = useState([]);
  const [listeInvitations, setInvitations] = useState([]);

  const listeNotifications = useSelector((state) => state.profil.notifications);

  const monProfil = useSelector((state) => state.profil.profil);
  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
    console.log(typeof listeContacts);
  }, [netStatus]);

  const [filtreMot, setFiltreMot] = useState("");
  //Fonction filtre barre de recherche
  function filtreContacts(e) {
    setFiltreMot(e.target.value);
  }
  const accessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector((state) => state.user.refresh);
  const dispatch = useDispatch();
  const navigationPage = useNavigate();

  const nbreNotif = useContext(NotifNbreContext);

  const [openAnnulerDemande, setOpenAnnulerDemande] = useState(false);
  const [openRefuserDemande, setOpenRefuserDemande] = useState(false);
  const [openAccepterDemande, setOpenAccepterDemande] = useState(false);
  const [selectInvitation, setSelectInvitation] = useState();

  const [loading, setLoading] = useState(true);
  const [circle, setCircle] = useState(false);

  const ouvrirAnnuler = (id) => {
    setSelectInvitation(id);
    setOpenAnnulerDemande(true);
    // console.log(id)
  };
  const ouvrirRefuser = (id) => {
    setSelectInvitation(id);
    setOpenRefuserDemande(true);
    // console.log(id)
  };
  const ouvrirAccepter = (id) => {
    setSelectInvitation(id);
    setOpenAccepterDemande(true);
    // console.log(id)
  };

  const refuserHandle = () => {
    const notifChat = listeNotifications.filter(
      (item) => item.id_target === selectInvitation
    );
    setCircle(true);
    refuserDemandeRedux(accessToken, selectInvitation)
      .then((reponse) => {
        setCircle(false);
        setOpenRefuserDemande(false);
        // console.log('demande refusé : ', reponse.data)
        contactsRedux(accessToken).then((response) => {
          // console.log(reponse.data)
          setListeContacts(response.data);
        });

        demandesRedux(accessToken).then((response) => {
          // console.log(reponse.data)
          setListeDemandes(response.data);
        });

        invitationsRedux(accessToken).then((response) => {
          // console.log(reponse.data)
          setInvitations(response.data);
        });

        UpdateNotification(notifChat[0].id, accessToken)
          .then((reponse) => {
            // console.log(reponse)
            // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
            NotificationsRedux(accessToken).then((reponse) => {
              dispatch({
                type: "Profil/addNotifications",
                payload: reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ),
              });

              nbreNotif.setNbre(
                reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ).length
              );
            });
          })
          .catch((erreur) => {
            // console.log(erreur)
          });

        // navigationPage('/contacts')
        setOpenRefuserDemande(false);
      })
      .catch((erreur) => {
        setCircle(false);
        // console.log('erreur de refus')
        // console.log(erreur)
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              refuserHandle();
            });
          }
        }
      });
  };
  const annulerHandle = () => {
    setCircle(true);
    annulerDemandeRedux(accessToken, selectInvitation)
      .then((reponse) => {
        // console.log('demande annulé : ', reponse.data)
        setCircle(false);
        setOpenAnnulerDemande(false);
        // contactsRedux(accessToken).then((response) => {
        //     // console.log(reponse.data)

        //     dispatch(
        //         {
        //             type: 'Profil/addContacts',
        //             payload: response.data
        //         }
        //     );
        // })

        // demandesRedux(accessToken).then((response) => {
        //     // console.log(reponse.data)

        //     dispatch(
        //         {
        //             type: 'Profil/addDemandes',
        //             payload: response.data
        //         }
        //     );

        // })

        // invitationsRedux(accessToken).then((response) => {
        //     // console.log(reponse.data)

        //     dispatch(
        //         {
        //             type: 'Profil/addInvitations',
        //             payload: response.data
        //         }
        //     );

        // })
        // navigationPage(0)
        navigationPage("/contacts");
        setListeDemandes(
          listeDemandes.filter((value) => value.id !== selectInvitation)
        );
        setOpenAnnulerDemande(false);
      })
      .catch((erreur) => {
        setCircle(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");
              annulerHandle();
            });
          }
        }
      });
  };
  const accepterHandle = () => {
    const notifChat = listeNotifications.filter(
      (item) => item.id_target === selectInvitation
    );
    // console.log(notifChat)
    setCircle(true);
    accepterDemandeRedux(accessToken, selectInvitation)
      .then((reponse) => {
        setCircle(false);
        setOpenAccepterDemande(false);
        // console.log('demande accepté : ', reponse.data)
        contactsRedux(accessToken).then((response) => {
          // console.log(reponse.data)
          setListeContacts(response.data);
        });

        demandesRedux(accessToken).then((response) => {
          // console.log(reponse.data)
          setListeDemandes(response.data);
        });

        invitationsRedux(accessToken).then((response) => {
          // console.log(reponse.data)
          setInvitations(response.data);
        });

        UpdateNotification(notifChat[0].id, accessToken)
          .then((reponse) => {
            // console.log(reponse)
            // console.log(listeNotifications.filter(item => item.participant_recepteur.id === monProfil.id && item.etat === false))
            NotificationsRedux(accessToken).then((reponse) => {
              dispatch({
                type: "Profil/addNotifications",
                payload: reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ),
              });

              nbreNotif.setNbre(
                reponse.data.filter(
                  (item) =>
                    item.participant_recepteur.id === monProfil.id &&
                    item.etat === false
                ).length
              );
            });
          })
          .catch((erreur) => {
            // console.log(erreur)
          });

        navigationPage("/contacts");
      })
      .catch((erreur) => {
        setCircle(false);
        // console.log('erreur')
        // console.log(erreur)
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });

              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });

              console.log("token refresh...");
              accepterHandle();
            });
          }
        }
      });
  };

  const [indexTab, setIndexTab] = useState(0);

  useEffect(() => {
    contactsRedux(accessToken)
      .then(async (reponse) => {
        // console.log(typeof reponse.data);
        if (typeof reponse.data !== "string") {
          setListeContacts(reponse.data);
        }

        await demandesRedux(accessToken).then((reponse) => {
          // console.log(reponse.data)
          if (typeof reponse.data !== "string") {
            setListeDemandes(reponse.data);
          }
        });

        await invitationsRedux(accessToken).then((reponse) => {
          // console.log(reponse.data)
          if (typeof reponse.data !== "string") {
            setInvitations(reponse.data);
          }
        });
        await setLoading(false);
        // navigationPage('/contacts')
      })
      .catch((erreur) => {
        // navigationPage('/contacts')
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError("Une erreur s'est produite.");
            setOpenError(true);
          }

          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");
              navigationPage("/contacts");
              setLoading(false);
              // handleContacts()
            });
          }
        }
      });
  }, []);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const closeErrorHandle = () => {
    setOpenError(false);
  };

  const participantSelect = (token, id) => {
    setLoading(true);
    participantSelectRedux(token, id)
      .then((reponse) => {
        dispatch({
          type: "evenements/addParticipantSelect",
          payload: reponse.data,
        });
        navigationPage(`/CompteParticipant/${id}/1`);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((erreur) => {
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError("Une erreur s'est produite.");
            setOpenError(true);
          }

          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              participantSelect(token, id);
            });
          }
        }
        setLoading(false);
      });
  };
  const location = useLocation();
  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter((item) => item.nom === "Accueil");
  breadcrumbs_temp.push({ url: location.pathname, nom: "Mes contacts" });
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
    dispatch(addHistorique("/"));
  }, []);

  return (
    <Container fluid>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />

      {/* Modal confirmation annuler demande */}
      <Modal
        className="d-flex align-items-center"
        show={openAnnulerDemande}
        style={{ fontFamily: PoliceDefaut }}
      >
        <Modal.Header
          closeVariant="white"
          className=""
          style={{ color: "white", backgroundColor: "#17418d" }}
          closeButton
          onHide={() => setOpenAnnulerDemande(false)}
        >
          <div className="h4 ">Confirmation</div>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment annuler l'envoi de cette invitation ?
            <div className="d-flex justify-content-end mt-3">
              {circle ? (
                <CircularProgress />
              ) : (
                <Button className="btn-recup" onClick={() => annulerHandle()}>
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenAnnulerDemande(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      {/* Modal confirmation refuser demande */}
      <Modal
        className="d-flex align-items-center"
        show={openRefuserDemande}
        onHide={() => setOpenRefuserDemande(false)}
        style={{ fontFamily: PoliceDefaut }}
      >
        <Modal.Header
          closeVariant="white"
          closeButton
          style={{ color: "white", backgroundColor: "#17418d" }}
        >
          <h4>Confirmation</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment refuser cette demande ?
            <div className="d-flex justify-content-end mt-3">
              {circle ? (
                <CircularProgress />
              ) : (
                <Button className="btn-recup" onClick={() => refuserHandle()}>
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenRefuserDemande(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      {/* Modal confirmation accepter demande */}
      <Modal
        className="d-flex align-items-center"
        show={openAccepterDemande}
        onHide={() => setOpenAccepterDemande(false)}
        style={{ fontFamily: PoliceDefaut }}
      >
        <Modal.Header
          closeVariant="white"
          closeButton
          style={{ color: "white", backgroundColor: "#17418d" }}
        >
          <h4>Confirmation</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment accepter cette demande ?
            <div className="d-flex justify-content-end mt-3">
              {circle ? (
                <CircularProgress />
              ) : (
                <Button className="btn-recup" onClick={() => accepterHandle()}>
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenAccepterDemande(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      <Row className="">
        <Navigation
          button={false}
          titre={"Mes contacts"}
          icone={pictos.contact_rond_icone}
          photo={monProfil.photo_path}
          retour="dashboard"
          menus={true}
        />
      </Row>

      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <Row className=" container-contacts  d-flex justify-content-center">
          <Row
            className=" mx-2 mb-4  d-flex align-items-center justify-content-center"
            style={{ width: "90%" }}
          >
            <SearchInput
              filtre={filtreContacts}
              placeholder={"Rechercher contacts"}
            />
            {/* <Col xl={2} className='' style={{ width: 'fit-content' }}>
                        <Row className='d-flex justify-content-center' style={{ textAlign: 'center', height: '20px', margin: '' }}>  {'Filtrer'} </Row>
                        <img className='row' src={pictos.filtrer_icone} alt="" style={{ width: '30px', margin: 'auto' }} />
                    </Col> */}
          </Row>
          <Box className="">
            <Box
              sx={{
                bgcolor: "background.body",
                flexGrow: 1,
                m: -3,
                overflowX: "hidden",
                borderRadius: "md",
              }}
            >
              <Tabs
                className="tab-contacts"
                aria-label="Pipeline"
                value={indexTab}
                onChange={(event, value) => setIndexTab(value)}
                sx={{ "--Tabs-gap": "0", margin: "auto" }}
              >
                <TabList
                  className="mb-2"
                  variant="plain"
                  sx={{
                    width: "100%",

                    mx: "auto",
                    pt: 2,
                    alignSelf: "flex-start",
                    [`& .${tabClasses.root}`]: {
                      bgcolor: "transparent",
                      boxShadow: "none",
                      "&:hover": {
                        bgcolor: "transparent",
                        fontWeight: "lg",
                        color: "#17418d",
                      },
                      [`&.${tabClasses.selected}`]: {
                        color: "#0e71b4",
                        fontWeight: "lg",
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          zIndex: 1,
                          bottom: "-1px",
                          left: "var(--ListItem-paddingLeft)",
                          right: "var(--ListItem-paddingRight)",
                          height: "2.5px",
                          bgcolor: "#0e71b4",
                        },
                      },
                    },
                  }}
                >
                  <Container>
                    <Row className="d-flex justify-content-center">
                      <Col sm="auto" className="border-end" style={{}}>
                        <Tab
                          className=""
                          sx={{
                            fontFamily: PoliceDefaut,
                            fontSize: ".85rem",
                            color: "#17418d",
                          }}
                        >
                          {"Contacts"}
                          <Chip
                            size="sm"
                            variant="soft"
                            sx={{
                              minWidth: "25px",
                              minHeight: "25px",
                              padding: "",
                              ml: 1,
                              fontFamily: PoliceDefaut,
                              textAlign: "center",
                              backgroundColor:
                                indexTab === 0 ? "#0e71b4" : "#17418d",
                              color: "white",
                            }}
                          >
                            {listeContacts.length ? listeContacts.length : 0}
                          </Chip>
                        </Tab>
                      </Col>
                      <Col sm="auto" className="border-end" style={{}}>
                        <Tab
                          sx={{
                            fontFamily: PoliceDefaut,
                            fontSize: ".85rem",
                            color: "#17418d",
                          }}
                        >
                          {"Demandes envoyées"}
                          <Chip
                            size="sm"
                            variant="soft"
                            sx={{
                              minWidth: "25px",
                              minHeight: "25px",
                              ml: 1,
                              fontFamily: PoliceDefaut,
                              textAlign: "center",
                              backgroundColor:
                                indexTab === 1 ? "#0e71b4" : "#17418d",
                              color: "white",
                            }}
                          >
                            {listeDemandes ? listeDemandes.length : 0}
                          </Chip>
                        </Tab>
                      </Col>
                      <Col sm="auto" className="" style={{}}>
                        <Tab
                          sx={{
                            fontFamily: PoliceDefaut,
                            fontSize: ".85rem",
                            color: "#17418d",
                          }}
                        >
                          {"Demandes reçues"}
                          <Chip
                            size="sm"
                            variant="soft"
                            sx={{
                              minWidth: "25px",
                              minHeight: "25px",
                              ml: 1,
                              fontFamily: PoliceDefaut,
                              textAlign: "center",
                              backgroundColor:
                                indexTab === 2 ? "#0e71b4" : "#17418d",
                              color: "white",
                            }}
                          >
                            {listeInvitations ? listeInvitations.length : 0}
                          </Chip>
                        </Tab>
                      </Col>
                    </Row>
                  </Container>
                </TabList>

                <Box
                  sx={(theme) => ({
                    "--bg": theme.vars.palette.background.surface,
                    background: "var(--bg)",
                    boxShadow: "0 0 0 100vmax var(--bg)",
                    clipPath: "inset(0 -100vmax)",
                    px: 4,
                    py: 2,
                  })}
                >
                  <TabPanel value={0} sx={{ fontFamily: PoliceDefaut }}>
                    {listeContacts ? (
                      listeContacts.filter((item) => {
                        return filtreMot.toLowerCase() === ""
                          ? item
                          : toNormalForm(item.user.nom.toLowerCase()).includes(
                              toNormalForm(filtreMot.toLowerCase())
                            ) ||
                              toNormalForm(
                                item.user.prenom.toLowerCase()
                              ).includes(
                                toNormalForm(filtreMot.toLowerCase())
                              ) ||
                              toNormalForm(
                                item.fonction.toLowerCase()
                              ).includes(
                                toNormalForm(filtreMot.toLowerCase())
                              ) ||
                              toNormalForm(
                                item.organisation.nom.toLowerCase()
                              ).includes(toNormalForm(filtreMot.toLowerCase()));
                      }).length === 0 ? (
                        <p
                          className=""
                          style={{
                            textAlign: "center",
                            color: "#17418d",
                            fontSize: "120%",
                          }}
                        >
                          Aucun résultat trouvé
                        </p>
                      ) : (
                        listeContacts
                          .filter((item) => {
                            return filtreMot.toLowerCase() === ""
                              ? item
                              : toNormalForm(
                                  item.user.nom.toLowerCase()
                                ).includes(
                                  toNormalForm(filtreMot.toLowerCase())
                                ) ||
                                  toNormalForm(
                                    item.user.prenom.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  ) ||
                                  toNormalForm(
                                    item.fonction.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  ) ||
                                  toNormalForm(
                                    item.organisation.nom.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  );
                          })
                          .map((item, index) => {
                            const chat = chatSelected(
                              item.user.nom,
                              item.user.prenom,
                              item.fonction,
                              item.conversation.id,
                              item.photo_path,
                              item.organisation.nom,
                              item.organisation.logo,
                              item.from_structure
                            );
                            return (
                              <Row
                                key={index}
                                className="contact-item d-flex justify-content-center"
                              >
                                <Col className="">
                                  <Row>
                                    <Col
                                      xs={"auto"}
                                      style={{
                                        width: "fit-content",
                                        marginLeft: "9px",
                                        marginRight: "9px",
                                      }}
                                      className=" p-0 photo-profil-contact d-flex align-items-center"
                                    >
                                      <Avatar
                                        id="photoProfil"
                                        className=""
                                        src={item.photo_path}
                                        style={{}}
                                      />
                                    </Col>
                                    <Col
                                      className="p-1 d-flex align-items-center"
                                      style={{}}
                                    >
                                      <Container fluid>
                                        <Row
                                          className=""
                                          onClick={() =>
                                            participantSelect(
                                              accessToken,
                                              item.id
                                            )
                                          }
                                        >
                                          <Row className="noms-participant">
                                            <Col className="p-0">
                                              {upperFirst(
                                                item.user.prenom.toLowerCase()
                                              ) +
                                                " " +
                                                upperFirst(
                                                  item.user.nom.toLowerCase()
                                                )}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="p-0">
                                              {upperFirst(
                                                item.fonction.toLowerCase()
                                              )}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="p-0">
                                              {item.raison_sociale === "" ||
                                              item.raison_sociale === null
                                                ? !item.from_structure
                                                  ? upperFirst(
                                                      item.organisation.nom.toLowerCase()
                                                    )
                                                  : upperFirst(
                                                      item.organisation.adresse_organisation.ville.toLowerCase()
                                                    )
                                                : upperFirst(
                                                    item.raison_sociale.toLowerCase()
                                                  )}
                                            </Col>
                                          </Row>
                                          {/* <Row><Col className='p-0'>{item.raison_sociale === '' || item.raison_sociale === null ? !item.from_structure ? null : upperFirst(item.organisation.adresse_organisation.ville.toLowerCase()) : upperFirst(item.raison_sociale.toLowerCase())}</Col></Row> */}
                                        </Row>
                                        <Row
                                          className=""
                                          style={{
                                            cursor: "default",
                                            marginTop: "12px",
                                          }}
                                        >
                                          {item.from_structure ? null : (
                                            <Col
                                              sm="auto"
                                              className=" p-0"
                                              style={{}}
                                            >
                                              <img
                                                className="logo-contact"
                                                src={item.organisation.logo}
                                                alt=""
                                                width="auto"
                                                height="auto"
                                              />
                                            </Col>
                                          )}

                                          {/* {item.conversation.id + ' ' + item.conversation.room} */}
                                        </Row>
                                      </Container>
                                    </Col>
                                    <Col
                                      xs={"auto"}
                                      style={{ width: "fit-content" }}
                                      className="p-0 d-flex justify-content-end align-items-end "
                                    >
                                      {/* <Button className='' variant='outline-primary' onClick={() => navigationPage(`/chat/${item.conversation.id}/${item.conversation.room}`)}>Message</Button> */}

                                      <Button
                                        style={{ marginRight: "20px" }}
                                        className="p-0 px-2 py-1 mb-2"
                                        variant="outline"
                                        onClick={() =>
                                          chatSelect(
                                            chat,
                                            dispatch,
                                            navigationPage,
                                            `/chat/${item.conversation.id}/${item.conversation.room}/1`,
                                            accessToken,
                                            setLoading
                                          )
                                        }
                                      >
                                        Message
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>

                                {/* onClick={() => chatSelect(message, dispatch, navigationPage, `/chat/${item.conversation.id}/${item.conversation.room}`, accessToken)} */}

                                {/* {item.conversation.id} */}
                              </Row>
                            );
                          })
                      )
                    ) : (
                      <></>
                    )}
                  </TabPanel>
                  <TabPanel value={1} sx={{ fontFamily: PoliceDefaut }}>
                    {listeDemandes ? (
                      listeDemandes.filter((item) => {
                        return filtreMot.toLowerCase() === ""
                          ? item
                          : toNormalForm(
                              item.a_participant.user.nom.toLowerCase()
                            ).includes(toNormalForm(filtreMot.toLowerCase())) ||
                              toNormalForm(
                                item.a_participant.user.prenom.toLowerCase()
                              ).includes(
                                toNormalForm(filtreMot.toLowerCase())
                              ) ||
                              toNormalForm(
                                item.a_participant.fonction.toLowerCase()
                              ).includes(
                                toNormalForm(filtreMot.toLowerCase())
                              ) ||
                              toNormalForm(
                                item.a_participant.organisation.nom.toLowerCase()
                              ).includes(toNormalForm(filtreMot.toLowerCase()));
                      }).length === 0 ? (
                        <p
                          className=""
                          style={{
                            textAlign: "center",
                            color: "#17418d",
                            fontSize: "120%",
                          }}
                        >
                          Aucun résultat trouvé
                        </p>
                      ) : (
                        listeDemandes
                          .filter((item) => {
                            return filtreMot.toLowerCase() === ""
                              ? item
                              : toNormalForm(
                                  item.a_participant.user.nom.toLowerCase()
                                ).includes(
                                  toNormalForm(filtreMot.toLowerCase())
                                ) ||
                                  toNormalForm(
                                    item.a_participant.user.prenom.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  ) ||
                                  toNormalForm(
                                    item.a_participant.fonction.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  ) ||
                                  toNormalForm(
                                    item.a_participant.organisation.nom.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  );
                          })
                          .map((item, index) => (
                            <Row
                              key={index}
                              className="contact-item d-flex justify-content-center"
                            >
                              <Col className="">
                                <Row>
                                  <Col
                                    xs={"auto"}
                                    style={{
                                      width: "fit-content",
                                      marginLeft: "9px",
                                      marginRight: "9px",
                                    }}
                                    className=" p-0 photo-profil-contact d-flex align-items-center"
                                  >
                                    <Avatar
                                      id="photoProfil"
                                      className=""
                                      src={item.a_participant.photo_path}
                                      style={{}}
                                    />
                                  </Col>
                                  <Col
                                    className="p-1 d-flex align-items-center"
                                    style={{}}
                                  >
                                    <Container fluid>
                                      <Row
                                        onClick={() =>
                                          participantSelect(
                                            accessToken,
                                            item.a_participant.id
                                          )
                                        }
                                      >
                                        <Row className="noms-participant">
                                          <Col className="p-0 px-1">
                                            {upperFirst(
                                              item.a_participant.user.prenom.toLowerCase()
                                            ) +
                                              " " +
                                              upperFirst(
                                                item.a_participant.user.nom.toLowerCase()
                                              )}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="p-0 px-1">
                                            {upperFirst(
                                              item.a_participant.fonction.toLowerCase()
                                            )}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="p-0 px-1">
                                            {item.a_participant
                                              .raison_sociale === "" ||
                                            item.a_participant
                                              .raison_sociale === null
                                              ? !item.a_participant
                                                  .from_structure
                                                ? upperFirst(
                                                    item.a_participant.organisation.nom.toLowerCase()
                                                  )
                                                : upperFirst(
                                                    item.a_participant.organisation.adresse_organisation.ville.toLowerCase()
                                                  )
                                              : upperFirst(
                                                  item.a_participant.raison_sociale.toLowerCase()
                                                )}
                                          </Col>
                                        </Row>
                                      </Row>
                                      <Row
                                        className="mt-2"
                                        style={{ cursor: "default" }}
                                      >
                                        {item.a_participant
                                          .from_structure ? null : (
                                          <Col
                                            sm="auto"
                                            className=" p-0 px-1"
                                            style={{}}
                                          >
                                            <img
                                              className="logo-contact"
                                              src={
                                                item.a_participant.organisation
                                                  .logo
                                              }
                                              alt=""
                                              width="auto"
                                              height="50px"
                                            />
                                          </Col>
                                        )}
                                      </Row>
                                    </Container>
                                  </Col>
                                  <Col
                                    xs={"auto"}
                                    style={{ width: "fit-content" }}
                                    className="p-0 d-flex justify-content-end align-items-end "
                                  >
                                    <Button
                                      style={{ marginRight: "20px" }}
                                      className="p-0 px-2 py-1 mb-2"
                                      variant="outline"
                                      onClick={() => ouvrirAnnuler(item.id)}
                                    >
                                      Annuler
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ))
                      )
                    ) : (
                      <></>
                    )}
                  </TabPanel>
                  <TabPanel value={2} sx={{ fontFamily: PoliceDefaut }}>
                    {listeInvitations ? (
                      listeInvitations.filter((item) => {
                        return filtreMot.toLowerCase() === ""
                          ? item
                          : toNormalForm(
                              item.de_participant.user.nom.toLowerCase()
                            ).includes(toNormalForm(filtreMot.toLowerCase())) ||
                              toNormalForm(
                                item.a_participant.user.prenom.toLowerCase()
                              ).includes(
                                toNormalForm(filtreMot.toLowerCase())
                              ) ||
                              toNormalForm(
                                item.a_participant.fonction.toLowerCase()
                              ).includes(
                                toNormalForm(filtreMot.toLowerCase())
                              ) ||
                              toNormalForm(
                                item.a_participant.organisation.nom.toLowerCase()
                              ).includes(toNormalForm(filtreMot.toLowerCase()));
                      }).length === 0 ? (
                        <p
                          className=""
                          style={{
                            textAlign: "center",
                            color: "#17418d",
                            fontSize: "120%",
                          }}
                        >
                          Aucun résultat trouvé
                        </p>
                      ) : (
                        listeInvitations
                          .filter((item) => {
                            return filtreMot.toLowerCase() === ""
                              ? item
                              : toNormalForm(
                                  item.de_participant.user.nom.toLowerCase()
                                ).includes(
                                  toNormalForm(filtreMot.toLowerCase())
                                ) ||
                                  toNormalForm(
                                    item.a_participant.user.prenom.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  ) ||
                                  toNormalForm(
                                    item.a_participant.fonction.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  ) ||
                                  toNormalForm(
                                    item.a_participant.organisation.nom.toLowerCase()
                                  ).includes(
                                    toNormalForm(filtreMot.toLowerCase())
                                  );
                          })
                          .map((item, index) => (
                            <Row
                              key={index}
                              className="contact-item d-flex justify-content-center"
                            >
                              <Col className="">
                                <Row>
                                  <Col
                                    sm="auto"
                                    style={{
                                      width: "fit-content",
                                      marginRight: "9px",
                                    }}
                                    className="p-0 mx-1 photo-profil-contact d-flex align-items-center"
                                  >
                                    <Avatar
                                      id="photoProfil"
                                      className=""
                                      src={item.de_participant.photo_path}
                                      style={{}}
                                    />
                                  </Col>
                                  <Col className="p-1" style={{}}>
                                    <div
                                      onClick={() =>
                                        participantSelect(
                                          accessToken,
                                          item.de_participant.id
                                        )
                                      }
                                    >
                                      <Row className="noms-participant">
                                        <Col>
                                          {upperFirst(
                                            item.de_participant.user.prenom.toLowerCase()
                                          ) +
                                            " " +
                                            upperFirst(
                                              item.de_participant.user.nom.toLowerCase()
                                            )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {upperFirst(
                                            item.de_participant.fonction.toLowerCase()
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {item.de_participant
                                            .raison_sociale === "" ||
                                          item.de_participant.raison_sociale ===
                                            null
                                            ? !item.de_participant
                                                .from_structure
                                              ? upperFirst(
                                                  item.de_participant.organisation.nom.toLowerCase()
                                                )
                                              : upperFirst(
                                                  item.de_participant.organisation.adresse_organisation.ville.toLowerCase()
                                                )
                                            : upperFirst(
                                                item.de_participant.raison_sociale.toLowerCase()
                                              )}
                                        </Col>
                                      </Row>
                                    </div>
                                    <Row>
                                      <Col
                                        sm="auto"
                                        className="d-flex justify-content-start mt-2"
                                        style={{ width: "fit-content" }}
                                      >
                                        {item.de_participant
                                          .from_structure ? null : (
                                          <img
                                            className="logo-contact"
                                            src={
                                              item.de_participant.organisation
                                                .logo
                                            }
                                            alt=""
                                            width="auto"
                                            height="50px"
                                          />
                                        )}
                                      </Col>
                                      <Col
                                        className="p-0 d-flex align-items-center justify-content-end"
                                        style={{}}
                                      >
                                        <Row
                                          xs={2}
                                          className=" d-flex justify-content-end"
                                          style={{ marginRight: "20px" }}
                                        >
                                          <Col
                                            className="d-flex justify-content-start"
                                            style={{
                                              width: "fit-content",
                                              padding: "0",
                                            }}
                                          >
                                            <Button
                                              className="btn-refuser p-0 px-2 py-1"
                                              variant="outline"
                                              onClick={() =>
                                                ouvrirRefuser(item.id)
                                              }
                                            >
                                              Refuser
                                            </Button>
                                          </Col>
                                          <Col
                                            style={{
                                              width: "fit-content",
                                              padding: "0",
                                            }}
                                            className="d-flex align-items-end mx-1"
                                          >
                                            <Button
                                              className="btn-accepter p-0 px-2 py-1"
                                              variant="outline"
                                              onClick={() =>
                                                ouvrirAccepter(item.id)
                                              }
                                            >
                                              Se connecter
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ))
                      )
                    ) : (
                      <></>
                    )}
                  </TabPanel>
                </Box>
              </Tabs>
            </Box>
          </Box>
        </Row>
      )}
    </Container>
  );
};

export default Contacts;
