import React, { useState } from "react";
import "../styles/programme.css";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { pictos } from "../assets/img/pictos";
import { useDispatch, useSelector } from "react-redux";
import { toLower, upperFirst } from "lodash";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Avatar, Badge, CircularProgress } from "@material-ui/core";
import { categories, couleurTheme, pictosCategorie } from "./fonctions";
import Navigation from "../components/Navigation";
import {
  contactsRedux,
  demandesRedux,
  envoyerDemandeRedux,
  invitationsRedux,
  refreshToken,
} from "../redux/profilService";
import {
  Check,
  HourglassEmptyTwoTone,
  HourglassFullTwoTone,
} from "@material-ui/icons";
import ErrorPopup from "../components/ErrorPopup";
import {
  participantSelectRedux,
  programmeSelectRedux,
} from "../redux/evenementsServices";
import Breadcrumb from "../components/Breadcrumb";
import { useEffect } from "react";
import { useContext } from "react";
import { BreadcrumbContext } from "../Context";
import Chemin from "../components/Chemin";
import textConnect from "../textConnect.json";
import { PoliceDefaut } from "../helpers/contants";

const ProgrammeDetail = () => {
  const programmeSelected = useSelector(
    (state) => state.evenements.programmeSelect
  );

  const monProfil = useSelector((state) => state.profil.profil);
  const evenementSelected = useSelector(
    (state) => state.evenements.evenementSelect
  );
  const participantSelected = useSelector(
    (state) => state.evenements.participantSelect
  );

  const accessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector((state) => state.user.refresh);

  const dispatch = useDispatch();
  const navigationPage = useNavigate();

  const param = useParams();

  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const closeErrorHandle = () => {
    setOpenError(false);
  };

  const [loading, setLoading] = useState(false);

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  const participantSelect = (token, id) => {
    setLoading(true);
    participantSelectRedux(token, id, evenementSelected.id)
      .then((reponse) => {
        dispatch({
          type: "evenements/addParticipantSelect",
          payload: reponse.data,
        });
        navigationPage(`/CompteParticipant/${id}/0`);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((erreur) => {
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError("Une erreur s'est produite.");
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              participantSelect(token, id);
            });
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        setLoading(false);
      });
  };
  const location = useLocation();
  const [openEnvoyerDemande, setOpenEnvoyerDemande] = useState(false);
  const [selectProfil, setSelectProfil] = useState("");
  const [circle, setCircle] = useState(false);
  const ouvrirEnvoyer = (id) => {
    setSelectProfil(id);
    setOpenEnvoyerDemande(true);
  };

  const handleEnvoiDemande = () => {
    setCircle(true);
    envoyerDemandeRedux(accessToken, selectProfil, evenementSelected.id)
      .then(async (reponse) => {
        // console.log('demande envoyé :', reponse.data)

        await programmeSelectRedux(accessToken, programmeSelected.id).then(
          (reponse) => {
            dispatch({
              type: "evenements/addProgrammeSelect",
              payload: reponse.data,
            });
            // window.open('/programmeDetail', '_top')
          }
        );
        setCircle(false);
        setOpenEnvoyerDemande(false);
        // participantsRedux(accessToken, dispatch, evenementSelected.id)
      })
      .catch((erreur) => {
        setCircle(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response.status === 401) {
          refreshToken(refresh_token).then(async (reponse) => {
            dispatch({
              type: "user/addRefresh",
              payload: reponse.data.refresh,
            });
            dispatch({
              type: "user/addAccess",
              payload: reponse.data.access,
            });
            console.log("token refresh...");

            setLoading(false);
            handleEnvoiDemande();
          });
        }
      });
  };

  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp =
    participantSelected === ""
      ? breadCrumbs.filter(
          (item) => item.nom !== upperFirst(toLower(programmeSelected.titre))
        )
      : breadCrumbs.filter(
          (item) =>
            item.url.split("/")[1] !== "programme" &&
            item.url.split("/")[1] !== "planStand"
        );
  breadcrumbs_temp.push({
    url: location.pathname,
    nom: upperFirst(toLower(programmeSelected.titre)),
  });
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
  }, []);
  const [indexPart, setIndexPart] = useState([]);
  useEffect(() => {
    if (selectProfil !== "") {
      setIndexPart((old) => [...old, selectProfil.toString()]);
    }
  }, [selectProfil]);

  return (
    <Container fluid>
      {/* <button onClick={() => console.log(location.pathname.split('/'))}>location</button> */}
      <Modal
        className="d-flex align-items-center"
        show={openEnvoyerDemande}
        onHide={() => setOpenEnvoyerDemande(false)}
        style={{ fontFamily: PoliceDefaut }}
      >
        <Modal.Header
          closeVariant="white"
          closeButton
          style={{ color: "white", backgroundColor: "#17418d" }}
        >
          <h4>Confirmation</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment envoyer une demande ?
            <div className="d-flex justify-content-end mt-3">
              {circle ? (
                <CircularProgress />
              ) : (
                <Button
                  className="btn-recup"
                  onClick={() => handleEnvoiDemande()}
                >
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenEnvoyerDemande(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />
      <Row>
        <Navigation
          button={true}
          titre={param.type === "0" ? "Programme" : "Ma journée"}
          icone={
            param.type === "0"
              ? pictos.programme_rond_icone
              : pictos.journee_rond_icone
          }
          evenement={evenementSelected.photo}
          photo={monProfil.photo_path}
          retour="dashboard"
          selected={param.type === "0" ? 1 : 3}
          open={(!open).toString()}
          menus={true}
        />
        {/* <NavbarParticipants button={true} titre={'Programme'} icone={pictos.programme_icone} evenement={evenementSelected.photo} photo={monProfil.photo_path} /> */}
      </Row>
      {/* <Row className='p-0'>
                <Breadcrumb
                    focus={param.type === '0' ? 'Programme' : 'Ma journée'}
                    third={true}
                    location={param.type === '0' ? `/programme` : `/majournee`}
                    subfocus={upperFirst(toLower(programmeSelected.titre))}
                    sublocation={location}
                    programme={true}
                />
            </Row> */}
      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      <Row className="programme-container">
        <Col>
          <Row className="programme-detail">
            <Col>
              <Row className="d-flex align-items-center mb-2 ">
                <Col
                  sm={"auto"}
                  className="programme-date mt-2 mx-2"
                  style={{
                    backgroundColor: couleurTheme(programmeSelected.categorie),
                    color: "white",
                    width: "fit-content",
                  }}
                >
                  {" "}
                  {programmeSelected.heure_fin === null ||
                  programmeSelected.heure_fin === undefined ||
                  programmeSelected.heure_fin === ""
                    ? programmeSelected.heure_deb
                    : programmeSelected.heure_deb +
                      " - " +
                      programmeSelected.heure_fin}{" "}
                </Col>
                {/* <Col xs={''} className='text-uppercase' style={{ color: couleurTheme(programmeSelected.categorie.couleur) }}>{programmeSelected.theme}</Col> */}
                <Col className="stand1 pt-2" style={{ padding: "0" }}>
                  {programmeSelected.categorie.for_room ? (
                    (programmeSelected.stand.id === "" ||
                      programmeSelected.stand.id === undefined) &&
                    programmeSelected.salle === "" ? null : (
                      <div className="d-flex align-items-end">
                        <img
                          title={
                            programmeSelected.stand.code === undefined ||
                            programmeSelected.stand.id === ""
                              ? ""
                              : "Voir le stand"
                          }
                          onClick={() =>
                            navigationPage(
                              programmeSelected.stand.code === undefined ||
                                programmeSelected.stand.id === ""
                                ? ""
                                : "/planStand/" + programmeSelected.stand.id
                            )
                          }
                          src={pictos.lieu_icone}
                          alt=""
                          style={{
                            height: "25px",
                            width: "auto",
                            cursor:
                              programmeSelected.stand.code === undefined ||
                              programmeSelected.stand.id === ""
                                ? ""
                                : "pointer",
                          }}
                        />
                        <div>
                          {upperFirst(toLower(programmeSelected.salle))}
                        </div>
                      </div>
                    )
                  ) : programmeSelected.stand.code === undefined ||
                    programmeSelected.stand.code === "" ? null : (
                    <div className="d-flex align-items-end">
                      <img
                        title="Voir le stand"
                        onClick={() =>
                          navigationPage(
                            programmeSelected.stand.id === undefined ||
                              programmeSelected.stand.id === ""
                              ? ""
                              : "/planStand/" + programmeSelected.stand.id
                          )
                        }
                        src={pictos.lieu_icone}
                        alt=""
                        style={{
                          height: "25px",
                          width: "auto",
                          cursor: "pointer",
                        }}
                      />
                      <div>
                        {upperFirst(toLower(programmeSelected.stand.code))}
                      </div>
                    </div>
                  )}
                </Col>
                <Col
                  sm="auto"
                  className=" d-flex justify-content-end"
                  style={{ width: "fit-content" }}
                >
                  <img
                    className="col-sm-auto mt-1"
                    src={pictosCategorie(programmeSelected.categorie)}
                    alt=""
                    style={{ height: "50px" }}
                  />
                </Col>
              </Row>
              <Row className="mb-1" style={{ marginTop: "-12px" }}>
                <Row className="stand2" style={{}}>
                  <Col className=" p-0  mb-1 px-1" style={{ width: "" }}>
                    {programmeSelected.categorie.for_room ? (
                      (programmeSelected.stand.id === "" ||
                        programmeSelected.stand.id === undefined) &&
                      programmeSelected.salle === "" ? null : (
                        <div className="d-flex align-items-end">
                          <img
                            title={
                              programmeSelected.stand.code === undefined ||
                              programmeSelected.stand.id === ""
                                ? ""
                                : "Voir le stand"
                            }
                            onClick={() =>
                              navigationPage(
                                programmeSelected.stand.code === undefined ||
                                  programmeSelected.stand.id === ""
                                  ? ""
                                  : "/planStand/" + programmeSelected.stand.id
                              )
                            }
                            src={pictos.lieu_icone}
                            alt=""
                            style={{
                              height: "25px",
                              width: "auto",
                              cursor:
                                programmeSelected.stand.code === undefined ||
                                programmeSelected.stand.id === ""
                                  ? ""
                                  : "pointer",
                            }}
                          />
                          <div>
                            {upperFirst(toLower(programmeSelected.salle))}
                          </div>
                        </div>
                      )
                    ) : programmeSelected.stand.code === undefined ||
                      programmeSelected.stand.code === "" ? null : (
                      <div className="d-flex align-items-end">
                        <img
                          title="Voir le stand"
                          onClick={() =>
                            navigationPage(
                              programmeSelected.stand.id === undefined ||
                                programmeSelected.stand.id === ""
                                ? ""
                                : "/planStand/" + programmeSelected.stand.id
                            )
                          }
                          src={pictos.lieu_icone}
                          alt=""
                          style={{
                            height: "25px",
                            width: "auto",
                            cursor: "pointer",
                          }}
                        />
                        <div>
                          {upperFirst(toLower(programmeSelected.stand.code))}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Col
                  sm="auto"
                  className="mb-2"
                  style={{ color: couleurTheme(programmeSelected.categorie) }}
                >
                  {programmeSelected.theme === undefined ||
                  programmeSelected.theme === "" ||
                  programmeSelected.theme === null
                    ? `${upperFirst(
                        toLower(programmeSelected.categorie.label)
                      )}`
                    : programmeSelected.categorie.label === "" ||
                      programmeSelected.categorie.label === null
                    ? `${upperFirst(toLower(programmeSelected.theme))}`
                    : `${upperFirst(
                        toLower(programmeSelected.categorie.label)
                      )} - ${upperFirst(toLower(programmeSelected.theme))}`}
                </Col>
              </Row>
              <Row>
                <Col
                  className="programme-titre"
                  style={{ color: couleurTheme(programmeSelected.categorie) }}
                >
                  {upperFirst(toLower(programmeSelected.titre))}
                </Col>
              </Row>
              <Row className="" style={{ textAlign: "" }}>
                <Col className="" style={{ wordBreak: "break-word" }}>
                  <p
                    style={{ color: "#17418d" }}
                    dangerouslySetInnerHTML={{
                      __html: programmeSelected.description,
                    }}
                  />
                </Col>
                {/* <Col sm={''} className=' border programme-description'><p className=''>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto reprehenderit dolores expedita, enim doloremque corporis iste eveniet unde! Labore, unde?</p></Col> */}
              </Row>
              <Row
                className=""
                style={{ borderTop: "2px solid #00a7d5", marginLeft: "2px" }}
              >
                <Container className="intervenants-container">
                  {programmeSelected.intervenants.length ? (
                    <Row
                      className="font-weight-bold mb-2 h5 mt-2"
                      style={{ color: "#00a7d5" }}
                    >
                      Intervenants
                    </Row>
                  ) : null}
                  {loading ? (
                    <div className="d-flex justify-content-center p-4">
                      <CircularProgress />
                    </div>
                  ) : (
                    programmeSelected.intervenants.map((item, index) => (
                      <Row
                        key={index}
                        className=" intervenant-item d-flex align-items-center"
                      >
                        <Col
                          onClick={() =>
                            item.id === monProfil.id
                              ? ""
                              : participantSelect(accessToken, item.id)
                          }
                          sm="auto"
                          className="photo-profil-participant "
                          style={{ cursor: "pointer" }}
                        >
                          <Badge
                            className="d-flex align-items-center justify-content-left"
                            style={{ width: "fit-content" }}
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={[
                              categories(
                                item.profil.photo,
                                item.profil.description,
                                item.is_expert,
                                item.is_speaker
                              ).map((item2, index) => (
                                <Avatar
                                  title={item2.title}
                                  key={index}
                                  className="col"
                                  src={item2.icone}
                                  alt=""
                                  style={{
                                    width: "18px",
                                    height: "auto",
                                    marginRight: "0",
                                    left: "-5px",
                                    backgroundColor: "  white",
                                  }}
                                />
                              )),
                            ]}
                          >
                            <Avatar
                              id="photoProfil"
                              className=""
                              src={item.photo_path}
                              style={{}}
                            />
                          </Badge>
                        </Col>
                        <Col
                          onClick={() =>
                            item.id === monProfil.id
                              ? ""
                              : participantSelect(accessToken, item.id)
                          }
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                        >
                          <Row className="noms-participant">
                            {" "}
                            {upperFirst(item.user.prenom.toLowerCase()) +
                              " " +
                              upperFirst(item.user.nom.toLowerCase())}
                          </Row>
                          <Row>{upperFirst(item.fonction.toLowerCase())}</Row>
                          <Row>
                            {item.raison_sociale === undefined ||
                            item.raison_sociale === "" ||
                            item.raison_sociale === null
                              ? !item.from_structure
                                ? upperFirst(
                                    item.organisation.nom.toLowerCase()
                                  )
                                : item.organisation.adresse_organisation.ville
                              : upperFirst(item.raison_sociale.toLowerCase())}
                          </Row>
                        </Col>

                        <Col
                          sm="auto"
                          className="d-flex justify-content-end"
                          style={{ width: "fit-content" }}
                        >
                          {item.is_contact_status === "accepted" ? (
                            <img
                              src={pictos.demande_valide_icone}
                              alt=""
                              style={{ width: "30px" }}
                            />
                          ) : item.is_contact_status === "pending" ||
                            (indexPart.filter(
                              (value) => value === item.id
                            )[0] === item.id &&
                              programmeSelected.intervenants.filter(
                                (val) => val.id === item.id
                              )[0].is_contact_status === "pending") ? (
                            <img
                              src={pictos.demande_attente_icone}
                              alt=""
                              style={{ width: "30px" }}
                            />
                          ) : item.is_contact_status === "invite" ? (
                            <img
                              title="Ajouter"
                              onClick={() => ouvrirEnvoyer(item.id)}
                              src={pictos.ajout_contact_icone}
                              alt=""
                              style={{ width: "30px", cursor: "pointer" }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    ))
                  )}

                  {programmeSelected.intervenants.length ? (
                    <Row className="coordonnee-info"></Row>
                  ) : null}
                </Container>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProgrammeDetail;
