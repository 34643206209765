import React from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import "../styles/suppression.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { useState } from "react";
import Navigation from "../components/Navigation";
import { useDispatch, useSelector } from "react-redux";
import Chemin from "../components/Chemin";
import axios from "axios";
import { URL } from "../redux/api";
import { deconnexion } from "./fonctions";
import { useContext } from "react";
import { LoggedContext, NotifNbreContext } from "../Context";
import { refreshToken } from "../redux/profilService";
import ErrorPopup from "../components/ErrorPopup";
import { CircularProgress } from "@material-ui/core";
import { resetUser } from "../redux/authSlice";
import { resetEvenements } from "../redux/evenementsSlice";
import { resetProfil } from "../redux/profilSlice";
import textConnect from "../textConnect.json";
import { PoliceDefaut } from "../helpers/contants";

const SuppressionCompte = () => {
  // const param = useParams()
  const monProfil = useSelector((state) => state.profil.profil);

  const location = useLocation();

  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const closeErrorHandle = () => {
    setOpenError(false);
  };

  //Déconnexion
  const loggedContext = useContext(LoggedContext);
  const navigationPage = useNavigate();
  const nbreNotif = useContext(NotifNbreContext);

  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState(monProfil.user.email);

  const userToken = useSelector((state) => state.user.access);

  const [openCloture, setOpenCloture] = useState(false);

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter((item) => item.nom === "Accueil");
  breadcrumbs_temp.push({ url: location.pathname, nom: "Demande de clôture" });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
  }, []);

  const clotureCompte = (email) => {
    setLoading(true);
    axios
      .post(
        `${URL}/api/closeaccount`,
        {
          mail: email,
        },
        {
          headers: {
            Authorization: "JWT " + userToken,
          },
        }
      )
      .then((response) => {
        deconnexion(loggedContext, navigationPage);
        dispatch(resetUser());
        dispatch(resetEvenements());
        dispatch(resetProfil());
        nbreNotif.setNbre(0);
      })
      .catch((erreur) => {
        setLoading(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
        } else {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
      });
  };
  return (
    <Container fluid>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />

      {/* Modal confirmation suppression discussion */}
      <Modal
        show={openCloture}
        style={{ fontFamily: PoliceDefaut, marginTop: "10%" }}
        onHide={() => setOpenCloture(false)}
      >
        <Modal.Header
          closeVariant="white"
          closeButton
          style={{ color: "white", backgroundColor: "#17418d" }}
        >
          <h4>Confirmation</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment clôturer votre compte ?
            <div className="d-flex justify-content-end mt-3">
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  className="btn-recup"
                  onClick={() => clotureCompte(mail)}
                >
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenCloture(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      <Row>
        <Navigation
          photo={monProfil.photo_path}
          button={false}
          titre={"Demande de clôture de compte"}
          retour="dashboard"
          menus={true}
        />
      </Row>
      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      <Row>
        <Form className="form-suppression">
          <Form.Group className="my-3">
            {/* <Form.Label>Demande</Form.Label> */}
            <Form.Control
              disabled
              onChange={(e) => setMail(e.target.value)}
              value={mail}
              style={{ borderRadius: "50px", color: "#17418d" }}
              className=""
              type="email"
              placeholder="Saisir votre adresse mail"
            />
          </Form.Group>
          <Form.Group className=" d-flex justify-content-end">
            <Button
              onClick={() => setOpenCloture(true)}
              disabled={mail === "" ? true : false}
              style={{ borderRadius: "50px", backgroundColor: "#17418d" }}
              className=""
              variant="primary"
            >
              Envoyer
            </Button>
          </Form.Group>
        </Form>

        {/* <form className='form-suppression' style={{ fontSize: '120%' }}>
                            <Row>
                              <Col>
                                   Votre compte a été supprimé
                             </Col>
                           </Row>
                          <Row className='d-flex justify-content-center mt-3'>
                              <Button onClick={(e) => closeTab(e)} style={{ borderRadius: '50px', backgroundColor: '#17418d', width: '100px' }} className='' variant="primary" type="">
                                    Fermer
                             </Button>
                  </Row>

                 </form> */}
      </Row>
    </Container>
  );
};

export default SuppressionCompte;
